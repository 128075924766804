import { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import {
  H4,
  getColorWithAlpha,
  Typography,
  Icon
} from '@elfsight-universe/ui-common';
import CheckBold12 from '@icons/check-bold-12.svg';
import { OnboardingCircularProgress } from './onboarding-cicrular-progress';

export type OnboardingPreviewProps = {
  progress: number;
  active?: boolean;
  done: boolean;
  onClick: () => void;
  large?: boolean;
};

export const OnboardingPreview = forwardRef<
  HTMLDivElement,
  OnboardingPreviewProps
>(function _OnboardingPreview(
  { progress, active, onClick, done, large, ...forwardingProps },
  forwardingRef
) {
  return (
    <Container
      $active={active}
      $large={large}
      onClick={onClick}
      ref={forwardingRef}
      {...forwardingProps}
    >
      <ProgressWrapper>
        {done ? (
          <DoneIconWrapper>
            <DoneIcon component={CheckBold12} size={10} />
          </DoneIconWrapper>
        ) : (
          <OnboardingCircularProgress progress={progress} />
        )}
      </ProgressWrapper>

      <TextContent>
        <Caption>Getting Started</Caption>
        <Typography variant="captionSmall" color="gray50">
          {progress}% Complete
        </Typography>
      </TextContent>
    </Container>
  );
});

const Caption = styled(H4)`
  color: ${({ theme }) => theme.colors.gray70};
`;

const Container = styled.div<{
  $active?: boolean;
  $large?: boolean;
}>`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.gray10};
  transition: color 0.2s, background 0.2s;
  background: ${({ theme }) => theme.colors.transparent};
  padding: 4px 8px;

  &:hover {
    background: ${({ theme }) => getColorWithAlpha(theme.colors.accent, 0.05)};

    ${Caption} {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  &:active {
    background: ${({ theme }) => getColorWithAlpha(theme.colors.accent, 0.1)};

    ${Caption} {
      color: ${({ theme }) => theme.colors.black};
    }
  }

  ${({ $active, theme }) =>
    $active &&
    css`
      background: ${getColorWithAlpha(theme.colors.accent, 0.1)};

      ${Caption} {
        color: ${({ theme }) => theme.colors.black};
      }
    `}

  ${({ $large, theme }) =>
    $large &&
    css`
      padding: 12px 16px;
      gap: 16px;

      ${Caption} {
        color: ${theme.colors.black};
      }

      ${TextContent} {
        flex-direction: row;
        justify-content: space-between;
        gap: 0;
        align-items: center;
      }

      ${ProgressWrapper} {
        order: 1;
      }
    `}
`;

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2px;
`;

const ProgressWrapper = styled.div`
  display: flex;
  flex-shrink: 0;
`;

const DoneIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.accentTransparent};
`;

const DoneIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.accent};
`;
