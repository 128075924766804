import { useState, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';
import { Minus, Plus } from '@elfsight/icons';
import { IconButton } from '../buttons';
import { FAQItem } from './faq-types';

export type FaqCollapseItemProps = FAQItem &
  HTMLAttributes<HTMLElement> & { open: boolean };

export function FaqCollapseItem({
  question,
  answer,
  open,
  onClick,
  ...forwardingProps
}: FaqCollapseItemProps) {
  const [hovered, setHovered] = useState(false);

  return (
    <Container
      onMouseOver={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
      _open={open}
      {...forwardingProps}
    >
      <Content>
        <Question>{question}</Question>
        <AnswerWrapper>
          <Answer>
            <Spacer />
            {answer}
          </Answer>
        </AnswerWrapper>
      </Content>
      <ButtonContainer>
        <IconButton
          small
          active={hovered}
          icon={open ? Minus : Plus}
          onClick={onClick}
        />
      </ButtonContainer>
    </Container>
  );
}

const Question = styled.div`
  ${({ theme }) => theme.font.title3};
  text-align: left;
  display: flex;
  align-items: center;
`;

const AnswerWrapper = styled.div`
  height: auto;
  transition: all 0.2s;
`;

const Container = styled.div<{ _open: boolean }>`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  :not(:last-child) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.gray10};
  }
  cursor: pointer;

  ${AnswerWrapper} {
    ${({ _open }) => css`
      max-height: ${_open ? 1000 : 0}px;
      opacity: ${_open ? 1 : 0};
    `}
  }
`;

const Spacer = styled.div`
  height: 12px;
`;

const Answer = styled.div`
  ${({ theme }) => theme.font.text};
  text-align: left;
  color: ${({ theme }) => theme.colors.gray70};

  a {
    color: ${({ theme }) => theme.colors.accent};
  }
`;

const Content = styled.div`
  flex-grow: 1;
  overflow: hidden;
  margin-right: 8px;
`;

const ButtonContainer = styled.div`
  margin-top: -3px;
  margin-bottom: -3px;
`;
