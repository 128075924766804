import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_APP_FEATURES_PATH,
  GetAppFeaturesResponse
} from '@elfsight-universe/service-core-contracts/widget';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useAppFeaturesQuery(
  appAlias: string | undefined,
  { enabled, ...options }: UseQueryOptions<GetAppFeaturesResponse> = {}
) {
  const { isAuthenticated } = useUser();
  return useQuery<GetAppFeaturesResponse>(
    [GET_APP_FEATURES_PATH, appAlias],
    () =>
      client
        .get(GET_APP_FEATURES_PATH, {
          searchParams: { appAlias: appAlias as string }
        })
        .json<GetAppFeaturesResponse>(),
    {
      retry: false,
      enabled:
        enabled !== undefined
          ? enabled && Boolean(appAlias) && isAuthenticated
          : Boolean(appAlias) && isAuthenticated,
      ...options
    }
  );
}
