import { Exclude, Expose, Type } from 'class-transformer';
import { IdentityAndAccessError } from '../errors';
import { UserRole } from './enums/user-role.enum';

export const GET_ME_PATH = 'identity-and-access/get-me';

@Exclude()
export class GetMeResponseEffectiveAccount {
  @Expose()
  pid: string;

  @Expose()
  email: string;

  @Expose()
  firstName: string | null;

  @Expose()
  lastName: string | null;

  @Expose()
  avatar: string | null;
}

@Exclude()
export class GetMeResponse {
  @Expose()
  pid: string;

  @Expose()
  accountPid: string;

  @Expose()
  email: string;

  @Expose()
  firstName: string | null;

  @Expose()
  lastName: string | null;

  @Expose()
  profilePictureURL: string | null;

  @Expose()
  verified: boolean;

  @Expose()
  isAffiliate: boolean;

  @Expose()
  isImpersonateSession: boolean;

  @Expose()
  isRecentlyCreated: boolean;

  @Expose()
  @Type(() => GetMeResponseAccountAffiliate)
  accountAffiliate?: {
    paddleId: string;
    blockedAt: Date | null;
  };

  @Expose()
  roles: UserRole[];

  @Expose()
  owningAccountPid: string;

  @Expose()
  @Type(() => GetMeResponseEffectiveAccount)
  effectiveAccount: GetMeResponseEffectiveAccount;

  @Expose()
  error?: IdentityAndAccessError;
}

@Exclude()
export class GetMeResponseAccountAffiliate {
  @Expose() paddleId: string;
  @Expose() blockedAt: Date | null;
}
