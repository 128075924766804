import { useForm } from 'react-hook-form';
import { classValidatorResolver } from '@hookform/resolvers/class-validator';
import { CreateInstallationRequestAppealRequest } from '@elfsight-universe/service-core-contracts/appeal/create-installation-request-appeal';
import { useUser } from '@modules/_app';

export function useRequestInstallationForm(
  widgetPid: string,
  appAlias: string
) {
  const { user } = useUser();

  return useForm({
    mode: 'onSubmit',
    resolver: classValidatorResolver(CreateInstallationRequestAppealRequest),
    defaultValues: {
      platform: '',
      where: '',
      details: '',
      additional: '',
      accessDetails: '',
      contactEmail: user?.email || '',
      widgetPid,
      appAlias
    },
    shouldFocusError: false
  });
}
