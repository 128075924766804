import { MouseEventHandler, MouseEvent, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { ElfsightGlyph } from '@elfsight/icons';
import { Tooltip, Icon, Link } from '@elfsight-universe/ui-common';
import { useHeader } from './header-provider';

type HeaderProps = {
  onClick?: MouseEventHandler;
  tooltip?: ReactNode;
  disabled?: boolean;
  href?: string;
};

export const HeaderLogo = ({
  href = '/',
  onClick,
  tooltip = 'My Apps',
  disabled,
  ...forwardingProps
}: HeaderProps) => {
  const { toggleMobileNav } = useHeader();

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    toggleMobileNav(false);

    if (onClick) {
      onClick(e);
      return;
    }
  };

  if (disabled) {
    return (
      <IconButton disabled>
        <Icon component={ElfsightGlyph} size={28} fill="black" />
      </IconButton>
    );
  }

  return (
    <Link href={href} onClick={handleClick} {...forwardingProps}>
      <Tooltip content={tooltip}>
        <IconButton>
          <Icon component={ElfsightGlyph} size={28} fill="brand" />
        </IconButton>
      </Tooltip>
    </Link>
  );
};

const IconButton = styled.button`
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  outline: none;
  -webkit-tap-highlight-color: transparent;
  border-radius: 4px;
  transition: all 0.2s;

  ${({ theme: { colors }, disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
      :hover {
        background: ${colors.gray5};
      }
      :active {
        background: ${colors.gray10};
      }
    `}
`;
