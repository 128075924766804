import { useQuery } from '@tanstack/react-query';
import {
  GET_INSTANT_EXTENSION_CANDIDATES_PATH,
  GetInstantExtensionCandidatesResponse
} from '@elfsight-universe/service-core-contracts/billing/get-instant-extension-candidates';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useInstantExtensionCandidatesQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetInstantExtensionCandidatesResponse>(
    [GET_INSTANT_EXTENSION_CANDIDATES_PATH],
    () => client.get(GET_INSTANT_EXTENSION_CANDIDATES_PATH).json(),
    {
      enabled: isAuthenticated
    }
  );
}
