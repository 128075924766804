import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { InfiniteData } from '@tanstack/react-query';
import { useDisposedInSessionStorage } from '@elfsight-universe/ui-common/src/utils/use-disposed-in-session-storage';
import { GetPublishedAnnouncementsResponse } from '@elfsight-universe/service-core-contracts/announcement';
import { MODAL_OPEN_BODY_CLASS_NAME } from '@elfsight-universe/ui-common';
import {
  usePublishedAnnouncementsInfiniteQuery,
  useReportAnnouncementsVisitMutation
} from '@api';
import { useImpersonateSession } from '@modules/impersonate-session';
import { useUser } from '@modules/_app';
import { useInvalidateAnnouncementsRelatedQueries } from './utils';

const DISPOSABLE_INSTANCE_ID = 'announcements';
const CONFLICT_MODAL_OPEN_CLASSNAME = MODAL_OPEN_BODY_CLASS_NAME;
const CONFLICT_DROPDOWN_OPEN_DATA_ATTR = 'data-floating-ui-inert';

export type IAnnouncementsContext = {
  data?: InfiniteData<GetPublishedAnnouncementsResponse>;
  numberOfUnreadAnnouncements: number;
  nextPage: () => void;
  isLoading: boolean;
  isOpen: boolean;
  toggleOpen: (open?: boolean) => void;
};

export const AnnouncementsContext = createContext<IAnnouncementsContext | null>(
  null
);

type AnnouncementsProviderProps = PropsWithChildren;

export function AnnouncementsProvider({
  children
}: AnnouncementsProviderProps) {
  const checkOpenModalsTimeoutRef = useRef<NodeJS.Timeout>();
  const { user } = useUser();
  const { isInImpersonateSession } = useImpersonateSession();
  const {
    data,
    isLoading,
    fetchNextPage: nextPage,
    isFetchingNextPage
  } = usePublishedAnnouncementsInfiniteQuery({
    itemsPerPage: 10
  });

  const numberOfUnreadAnnouncements =
    data?.pages[0].meta.numberOfUnreadAnnouncements ?? 0;

  const [disposed, confirmDispose] = useDisposedInSessionStorage(
    DISPOSABLE_INSTANCE_ID,
    user?.accountPid
  );

  const [isOpen, setOpen] = useState(false);
  const [preventInitialOpen, setPreventInitialOpen] = useState<
    undefined | boolean
  >(undefined);

  /**
   * prevent open announces if some other modal/dropdown opened on init page
   */
  useEffect(() => {
    const body = document.querySelector('body');
    const rootNextNode = document.querySelector('#__next');

    const checkConflictModalOpen = () => {
      if (!body || !rootNextNode) {
        return;
      }

      if (
        body.classList.contains(CONFLICT_MODAL_OPEN_CLASSNAME) ||
        rootNextNode.hasAttribute(CONFLICT_DROPDOWN_OPEN_DATA_ATTR)
      ) {
        setPreventInitialOpen(true);
      } else {
        setPreventInitialOpen(false);
      }
    };

    if (body && rootNextNode) {
      checkOpenModalsTimeoutRef.current = setTimeout(
        checkConflictModalOpen,
        1000
      );
    }

    return () => {
      if (checkOpenModalsTimeoutRef.current) {
        clearTimeout(checkOpenModalsTimeoutRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const isInitialOpenPossible =
      preventInitialOpen !== undefined && !preventInitialOpen;

    if (
      isInitialOpenPossible &&
      !isOpen &&
      !!numberOfUnreadAnnouncements &&
      !disposed &&
      !isInImpersonateSession
    ) {
      setOpen(true);
    }
  }, [
    isOpen,
    disposed,
    numberOfUnreadAnnouncements,
    isInImpersonateSession,
    preventInitialOpen
  ]);

  const { mutate: reportAnnouncementsVisit } =
    useReportAnnouncementsVisitMutation(() => {
      invalidateAnnouncementsRelatedQueries();
    });
  const invalidateAnnouncementsRelatedQueries =
    useInvalidateAnnouncementsRelatedQueries();

  const toggleOpen = (state?: boolean) => {
    const value = state !== undefined ? state : !isOpen;
    setOpen(value);

    if (isInImpersonateSession) {
      return;
    }

    if (!value && numberOfUnreadAnnouncements > 0) {
      reportAnnouncementsVisit();
    }

    if (!value) {
      confirmDispose();
    }
  };

  return (
    <AnnouncementsContext.Provider
      value={{
        isOpen,
        toggleOpen,
        data,
        numberOfUnreadAnnouncements,
        nextPage,
        isLoading: isLoading || isFetchingNextPage
      }}
    >
      {children}
    </AnnouncementsContext.Provider>
  );
}

export function useAnnouncementsContext() {
  const context = useContext(AnnouncementsContext);

  if (context === null) {
    throw new Error(
      '`useAnnouncementsContext` must be nested inside an `AnnouncementsProvider`.'
    );
  }

  return context;
}
