import { useQuery } from '@tanstack/react-query';
import {
  GET_SWITCH_SUBSCRIPTION_PLAN_ESTIMATE_PATH,
  GetSwitchSubscriptionPlanEstimateRequest,
  GetSwitchSubscriptionPlanEstimateResponse,
  GetSwitchSubscriptionPlanEstimateResponseError
} from '@elfsight-universe/service-core-contracts/billing/get-switch-subscription-plan-estimate';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useSwitchSubscriptionPlanEstimateQuery({
  subscriptionPid,
  planPid
}: GetSwitchSubscriptionPlanEstimateRequest) {
  const { isAuthenticated } = useUser();

  return useQuery<
    GetSwitchSubscriptionPlanEstimateResponse,
    GetSwitchSubscriptionPlanEstimateResponseError
  >(
    [GET_SWITCH_SUBSCRIPTION_PLAN_ESTIMATE_PATH, planPid, subscriptionPid],
    () =>
      client
        .get(GET_SWITCH_SUBSCRIPTION_PLAN_ESTIMATE_PATH, {
          searchParams: { subscriptionPid, planPid },
          retry: {
            limit: 0
          }
        })
        .json<GetSwitchSubscriptionPlanEstimateResponse>(),
    {
      enabled: Boolean(planPid && subscriptionPid) && isAuthenticated
    }
  );
}
