import { Exclude, Expose, Type } from 'class-transformer';
import { IsString } from 'class-validator';
import { WidgetError } from '../errors';
import { WishlistTopicType } from './enums';

export const GET_WISHLIST_TOPICS_PATH =
  'community-integration/get-wishlist-topics';

export class GetWishlistTopicsRequest {
  @IsString()
  appAlias: string;
}

@Exclude()
export class GetWishlistTopicsResponse {
  @Expose()
  @Type(() => GetWishlistTopicsResponseWishlistTopic)
  payload: GetWishlistTopicsResponseWishlistTopic[];

  @Expose()
  @Type(() => GetWishlistTopicsResponseMeta)
  meta: {
    seeAllFeatureRequestsLink: string;
    requestFeatureLink: string;
  };
}

export type GetWishlistTopicsResponseError = WidgetError.APP_DOES_NOT_EXIST;

@Exclude()
export class GetWishlistTopicsResponseMeta {
  @Expose()
  seeAllFeatureRequestsLink: string;

  @Expose()
  requestFeatureLink: string;
}

@Exclude()
export class GetWishlistTopicsResponseWishlistTopic {
  @Expose()
  pid: string;

  @Expose()
  createdAt: Date;

  @Expose()
  title: string;

  @Expose()
  type: WishlistTopicType;

  @Expose()
  views: number;

  @Expose()
  replies: number;

  @Expose()
  voteCount: number;

  @Expose()
  link: string;

  @Expose()
  voteLink: string;

  @Expose()
  @Type(() => GetWishlistTopicsResponseWishlistTopicPoster)
  posters: GetWishlistTopicsResponseWishlistTopicPoster[];
}

@Exclude()
export class GetWishlistTopicsResponseWishlistTopicPoster {
  @Expose()
  @Type(() => GetWishlistTopicsResponseWishlistTopicPosterUser)
  user: {
    profilePicture: string;
  };
}

@Exclude()
export class GetWishlistTopicsResponseWishlistTopicPosterUser {
  @Expose()
  profilePicture: string;
}
