import {
  GET_ME_PATH,
  GetMeResponse
} from '@elfsight-universe/service-core-contracts/identity-and-access';

const { NEXT_PUBLIC_APP_DASHBOARD__BASE_URL } = process.env;

export async function getMeServerSide(
  cookie: string
): Promise<GetMeResponse | void> {
  const headers = new Headers({
    'Content-Type': 'application/json',
    Cookie: cookie
  });

  const response = await fetch(
    `${NEXT_PUBLIC_APP_DASHBOARD__BASE_URL}/core/${GET_ME_PATH}`,
    {
      method: 'GET',
      headers
    }
  );

  if (!response.ok || !response.body) {
    return;
  }

  return await response.json();
}
