import { useQuery } from '@tanstack/react-query';
import {
  GET_PROMOTED_APPS_PATH,
  GetPromotedAppsResponse
} from '@elfsight-universe/service-core-contracts/deals';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useGetPromotedAppsQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetPromotedAppsResponse>(
    [GET_PROMOTED_APPS_PATH],
    () => client.get(GET_PROMOTED_APPS_PATH).json(),
    {
      enabled: isAuthenticated
    }
  );
}
