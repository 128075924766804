import { forwardRef, useState, useEffect, InputHTMLAttributes } from 'react';
import { ControlProps, ControlSize } from '../control-types';
import {
  ControlContainer,
  ControlInner,
  ControlInput
} from '../control-layout';
import { useFocused } from '../use-focused';
import { InlineInputMeasure } from './inline-input-measure';

export type InlineInputProps = InputHTMLAttributes<HTMLInputElement> &
  ControlProps<{
    maxWidth?: number;
  }>;

export const InlineInput = forwardRef<HTMLInputElement, InlineInputProps>(
  function _InlineInput(
    {
      large,
      maxWidth,
      fullWidth,
      value,
      error,
      placeholder,
      onBlur,
      onFocus,
      className,
      ...forwardingProps
    },
    forwardingRef
  ) {
    const [measureText, setMeasureText] = useState(value || placeholder);
    const [focused, focusHandlers] = useFocused(onFocus, (event) => {
      if (event.target.value) {
        setMeasureText(event.target.value);
      }
      if (onBlur) {
        onBlur(event);
      }
    });

    useEffect(() => {
      setMeasureText(value || placeholder);
    }, [value, placeholder]);

    const size: ControlSize = large ? 'large' : 'medium';
    const fontSize = { large: 16, medium: 14 }[size];
    const height = { large: 48, medium: 36 }[size];

    if (!measureText) return null;

    return (
      <InlineInputMeasure fontSize={fontSize} text={measureText}>
        {({ width }) => (
          <ControlContainer
            _fullWidth={fullWidth}
            _fontSize={fontSize}
            _height={height}
            _maxWidth={maxWidth}
            _padding={PADDING}
            _focused={focused}
            _borderColor="transparent"
            _hasError={Boolean(error)}
            style={{
              display: width ? 'block' : 'none',
              width: !fullWidth ? width + PADDING * 2 + 2 : undefined
            }}
            className={className}
          >
            <ControlInner>
              <ControlInput
                value={value}
                placeholder={placeholder}
                ref={forwardingRef}
                {...focusHandlers}
                {...forwardingProps}
              />
            </ControlInner>
          </ControlContainer>
        )}
      </InlineInputMeasure>
    );
  }
);

const PADDING = 8;
