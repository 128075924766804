import { Exclude, Expose } from 'class-transformer';
import { IsOptional, IsString } from 'class-validator';
import { IdentityAndAccessError } from '../errors';

export const SWITCH_ACCOUNT_PATH = 'identity-and-access/switch-account';

export class SwitchAccountRequest {
  @IsOptional()
  @IsString()
  accountPid?: string;
}

@Exclude()
export class SwitchAccountResponse {
  @Expose()
  selectedAccountPid: string;
}

export type SwitchAccountResponseError =
  | IdentityAndAccessError.NOT_AUTHENTICATED
  | IdentityAndAccessError.ACCOUNT_DOES_NOT_EXIST;
