import { forwardRef } from 'react';
import addDays from 'date-fns/addDays';
import styled from 'styled-components';
import { formatLocaleDate } from '@elfsight-universe/ui-common';
import { createUpgradeURL } from '@modules/upgrade';
import { ListItem } from '../list-item';
import { IAppUsage } from './app-usage-types';
import { AppUsageCounter } from './app-usage-counter';
import { AppUsageBlocked } from './app-usage-blocked';

export type ViewsAppProps = IAppUsage & {
  onClick?: () => void;
  active?: boolean;
};

export const AppUsage = forwardRef<HTMLDivElement, ViewsAppProps>(
  function _ViewsApp(
    { app, appUsage, active = false, ...forwardingProps },
    forwardingRef
  ) {
    const blocked = appUsage.viewsUsage >= 1;
    const endsAt = formatLocaleDate(
      addDays(new Date(appUsage.viewsCountingPeriod.endsAt), 1)
    );

    return (
      <Container
        _isInteracting={active}
        ref={forwardingRef}
        {...forwardingProps}
      >
        <HeaderContainer>
          <Info>
            <AppName>{app.name}</AppName>
            {endsAt && <ResetDate>Resets on {endsAt}</ResetDate>}
          </Info>

          <AppUsageCounter appUsage={appUsage} />
        </HeaderContainer>

        {blocked && (
          <AppUsageBlocked
            resetDate={endsAt as string}
            upgradeURL={createUpgradeURL({
              app: app.alias,
              redirectBack: true,
              flow: true
            })}
          />
        )}
      </Container>
    );
  }
);

const Info = styled.div`
  flex-grow: 1;
  flex-basis: 66%;
  padding-right: 4px;
  color: ${({ theme }) => theme.colors.black};
`;

const AppName = styled.div`
  ${({ theme }) => theme.font.text};
`;

const ResetDate = styled.div`
  margin-top: 2px;
  ${({ theme }) => theme.font.caption};
  color: ${({ theme }) => theme.colors.gray50};
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Container = styled(ListItem)`
  display: flex;
  flex-direction: column;
`;
