import { IncomingHttpHeaders } from 'http';
import { WidgetError } from '@elfsight-universe/service-core-contracts/errors';
import {
  CREATE_WIDGET_PATH,
  CreateWidgetRequest,
  CreateWidgetResponse,
  CreateWidgetResponseError
} from '@elfsight-universe/service-core-contracts/widget/create-widget';
import { useMemoizedCSRFToken } from '@elfsight-universe/ky-csrf-protection';
import {
  CSRF_HEADER_NAME,
  CSRF_PATH
} from '@elfsight-universe/service-core-contracts/csrf';
import { getMeServerSide } from './get-me-server-side';

const { NEXT_PUBLIC_SERVICE_CORE__BASE_URL } = process.env;
const { NEXT_PUBLIC_APP_DASHBOARD__BASE_URL } = process.env;

export async function createWidgetServerSide(
  { appAlias, appReleaseVersion, templatePid }: CreateWidgetRequest,
  incomingHeaders?: IncomingHttpHeaders
): Promise<{
  data?: CreateWidgetResponse;
  errorCode?: number;
}> {
  const getToken = useMemoizedCSRFToken(
    `${NEXT_PUBLIC_SERVICE_CORE__BASE_URL}${CSRF_PATH}`,
    incomingHeaders?.cookie
  );

  const csrfToken = await getToken();

  const headers = new Headers({
    'Content-Type': 'application/json'
  });

  if (csrfToken) {
    headers.append(CSRF_HEADER_NAME, csrfToken);
  }

  if (incomingHeaders?.cookie) {
    headers.append('cookie', incomingHeaders.cookie);

    const user = await getMeServerSide(incomingHeaders.cookie);

    if (user) {
      headers.append('X-Account-PID', user.effectiveAccount.pid);
    }
  }

  const response = await fetch(
    `${NEXT_PUBLIC_APP_DASHBOARD__BASE_URL}/core/${CREATE_WIDGET_PATH}`,
    {
      method: 'POST',
      body: JSON.stringify({ appAlias, appReleaseVersion, templatePid }),
      headers,
      credentials: 'include'
    }
  );

  const data = await response.json();

  if (!response.ok) {
    const errorMessage = data.message as CreateWidgetResponseError;
    const errorCode =
      errorMessage === WidgetError.APP_DOES_NOT_EXIST ? 404 : response.status;

    return { errorCode, data: undefined };
  }

  return {
    errorCode: undefined,
    data: data as CreateWidgetResponse
  };
}
