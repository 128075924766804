import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_ONBOARDING_PATH,
  GetOnboardingResponse
} from '@elfsight-universe/service-core-contracts/onboarding/get-onboarding';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useOnboardingQuery(
  options: UseQueryOptions<GetOnboardingResponse> = {}
) {
  const { isAuthenticated } = useUser();
  return useQuery<GetOnboardingResponse>(
    [GET_ONBOARDING_PATH],
    () => client.get(GET_ONBOARDING_PATH).json(),
    {
      refetchOnWindowFocus: true,
      enabled: isAuthenticated,
      ...options
    }
  );
}
