import { IsUUID, IsEnum } from 'class-validator';
import { Exclude, Expose } from 'class-transformer';
import { WidgetError } from '../errors';
import { WidgetVisibility } from './enums';

export const CHANGE_WIDGET_VISIBILITY_PATH = 'widget/change-widget-visibility';

export class ChangeWidgetVisibilityRequest {
  @IsEnum(WidgetVisibility)
  visibility: WidgetVisibility;

  @IsUUID()
  widgetPid: string;
}

@Exclude()
export class ChangeWidgetVisibilityResponse {
  @Expose()
  visibility: WidgetVisibility;
}

export type ChangeWidgetVisibilityResponseError =
  WidgetError.WIDGET_DOES_NOT_EXIST;
