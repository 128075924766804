import { Exclude, Expose, Type } from 'class-transformer';
import { IsUUID } from 'class-validator';
import { GetSubscriptionAppInfoResponse } from '../billing';
import { Project } from './classes';

export const GET_PROJECT_BY_PID_PATH = 'project/get-project-by-pid';

export class GetProjectByPidRequest {
  @IsUUID()
  pid: string;
}

@Exclude()
export class GetProjectByPidResponse extends Project {
  @Expose()
  widgetsCount: number;

  @Expose()
  @Type(() => GetSubscriptionAppInfoResponse)
  subscriptionAppsInfo: GetSubscriptionAppInfoResponse[];

  @Expose()
  projectThumbnailURL?: string;
}

export type GetProjectByPidError = 'PROJECT_DOES_NOT_EXIST';
