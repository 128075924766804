import { IncomingHttpHeaders } from 'http';
import { useCookies } from 'react-cookie';
import { useEffect } from 'react';
import addDays from 'date-fns/addDays';
import { SIGN_UP_REFERER_META_COOKIE_NAME } from '@elfsight-universe/service-core-contracts/analytics/sign-up-referer-meta';

const { NEXT_PUBLIC_COMMON__SESSION_COOKIE_DOMAIN } = process.env;

export function useCatchReferer(headers?: IncomingHttpHeaders) {
  const [cookies, setCookie] = useCookies();

  const signUpRefererCookie = cookies[SIGN_UP_REFERER_META_COOKIE_NAME];
  const referer = headers?.referer;

  useEffect(() => {
    if (!signUpRefererCookie && referer) {
      setCookie(SIGN_UP_REFERER_META_COOKIE_NAME, referer, {
        domain: NEXT_PUBLIC_COMMON__SESSION_COOKIE_DOMAIN,
        path: '/',
        secure: true,
        sameSite: 'none',
        expires: addDays(new Date(), 30)
      });
    }
  }, [referer, signUpRefererCookie]);
}
