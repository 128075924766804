import { useQuery } from '@tanstack/react-query';
import {
  GET_OCCUPATIONS_PATH,
  GetOccupationsResponse
} from '@elfsight-universe/service-core-contracts/identity-and-access';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useOccupationsQuery() {
  const { isAuthenticated } = useUser();

  return useQuery<GetOccupationsResponse>(
    [GET_OCCUPATIONS_PATH],
    () => client.get(GET_OCCUPATIONS_PATH).json(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: isAuthenticated
    }
  );
}
