import { useQuery } from '@tanstack/react-query';
import {
  GET_APPS_USAGE_PATH,
  GetAppsUsageResponse
} from '@elfsight-universe/service-core-contracts/billing/get-apps-usage';
import { useUser } from '@modules/_app';
import { client } from '../client';

// TODO: Remove?
export function useAppsUsageQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetAppsUsageResponse>(
    [GET_APPS_USAGE_PATH],
    () => client.get(GET_APPS_USAGE_PATH).json(),
    {
      enabled: isAuthenticated
    }
  );
}
