import { Exclude, Expose } from 'class-transformer';
import { IsString } from 'class-validator';
import { BillingError } from '../errors';
import { SwitchPlanPaymentComparison } from './enums';

export const GET_SWITCH_SUBSCRIPTION_APP_ESTIMATE_PATH =
  'billing/get-switch-subscription-app-estimate';

export class GetSwitchSubscriptionAppEstimateRequest {
  @IsString()
  currentAppAlias: string;

  @IsString()
  newAppAlias: string;
}

@Exclude()
export class GetSwitchSubscriptionAppEstimateResponse {
  @Expose()
  immediatePaymentAmount: number;

  @Expose()
  nextPaymentAmount: number;

  @Expose()
  nextPaymentDate: Date;

  @Expose()
  switchPlanPaymentComparison: SwitchPlanPaymentComparison;

  @Expose()
  hasInstantExtensions: boolean;

  @Expose()
  updatePaymentMethodURL?: string;
}

export type GetSwitchSubscriptionAppEstimateResponseError =
  | BillingError.SUBSCRIPTION_DOES_NOT_EXIST
  | BillingError.SUBSCRIPTION_CANNOT_BE_UPDATED
  | BillingError.PLAN_DOES_NOT_EXIST;
