import { useQuery } from '@tanstack/react-query';
import {
  GET_USER_PREFERENCES_PATH,
  GetUserPreferencesResponse
} from '@elfsight-universe/service-core-contracts/identity-and-access/get-user-preferences';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useUserPreferencesQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetUserPreferencesResponse>(
    [GET_USER_PREFERENCES_PATH],
    () =>
      client.get(GET_USER_PREFERENCES_PATH).json<GetUserPreferencesResponse>(),
    {
      enabled: isAuthenticated
    }
  );
}
