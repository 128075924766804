import pickBy from 'lodash/pickBy';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_ACCOUNT_DEAL_BY_ALIAS_PATH,
  GetAccountDealByAliasRequest,
  GetAccountDealByAliasResponse
} from '@elfsight-universe/service-core-contracts/deals';
import { client } from '@api';
import { useUser } from '@modules/_app';
import { useEvents } from '@modules/_event-bus';

export const USE_DEAL_BY_ALIAS_QUERY_KEY = GET_ACCOUNT_DEAL_BY_ALIAS_PATH;

export function useDealByAliasQuery(
  dto: GetAccountDealByAliasRequest,
  { enabled, ...options }: UseQueryOptions<GetAccountDealByAliasResponse> = {}
) {
  const { isAuthenticated } = useUser();
  const canQuery = Boolean(dto.alias) && isAuthenticated;

  const query = useQuery<GetAccountDealByAliasResponse>(
    [USE_DEAL_BY_ALIAS_QUERY_KEY, Object.values(dto)],
    () =>
      client
        .get(USE_DEAL_BY_ALIAS_QUERY_KEY, {
          searchParams: pickBy({ ...dto }, (v) => v !== undefined)
        })
        .json(),
    {
      enabled: (enabled === undefined || enabled) && canQuery,
      ...options
    }
  );

  useEvents(['DealsUpdated'], () => query.refetch());

  return query;
}
