import { useQuery } from '@tanstack/react-query';
import {
  GET_ALL_CATEGORIES_PATH,
  GetAllCategoriesResponse
} from '@elfsight-universe/service-core-contracts/widget/get-all-categories';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useAllCategoriesQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetAllCategoriesResponse>(
    [GET_ALL_CATEGORIES_PATH],
    () => client.get(GET_ALL_CATEGORIES_PATH).json<GetAllCategoriesResponse>(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: isAuthenticated
    }
  );
}
