import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import pickBy from 'lodash/pickBy';
import {
  GET_LAST_CREATED_WIDGET_PATH,
  GetLastCreatedWidgetRequest,
  GetLastCreatedWidgetResponse,
  GetLastCreatedWidgetResponseError
} from '@elfsight-universe/service-core-contracts/widget';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useLastCreatedWidgetQuery(
  dto: GetLastCreatedWidgetRequest,
  {
    enabled,
    ...options
  }: Omit<
    UseQueryOptions<
      GetLastCreatedWidgetResponse,
      GetLastCreatedWidgetResponseError
    >,
    'queryFn' | 'queryKey'
  > = {}
) {
  const { isAuthenticated } = useUser();
  return useQuery<
    GetLastCreatedWidgetResponse,
    GetLastCreatedWidgetResponseError
  >(
    [GET_LAST_CREATED_WIDGET_PATH, dto.appAlias],
    () =>
      client
        .get(GET_LAST_CREATED_WIDGET_PATH, {
          searchParams: pickBy({ ...dto }, (v) => v !== undefined)
        })
        .json<GetLastCreatedWidgetResponse>(),
    {
      enabled:
        enabled !== undefined ? enabled && isAuthenticated : isAuthenticated,
      ...options
    }
  );
}
