import styled from 'styled-components';
import { QuestionOutline } from '@elfsight/icons';
import {
  getColorWithAlpha,
  Icon,
  prettifyEmailLineBreak,
  Tooltip,
  Typography
} from '@elfsight-universe/ui-common';

type CollaboratorsUsernameProps = {
  content: string;
  type: 'name' | 'email';
  isDeactivated?: boolean;
  isYou?: boolean;
  isInvitation?: boolean;
};

export function CollaboratorsUsername({
  content,
  type,
  isDeactivated,
  isYou,
  isInvitation,
  ...forwardingProps
}: CollaboratorsUsernameProps) {
  const isNameBold = type === 'name';
  const finalContent = (
    <>
      {type === 'email' ? prettifyEmailLineBreak(content) : content}{' '}
      {isYou ? '(you)' : ''}
    </>
  );

  return (
    <Container {...forwardingProps}>
      <Wrapper
        variant={isNameBold ? 'title4Semibold' : 'caption'}
        color={isDeactivated || isInvitation ? 'gray50' : 'black'}
      >
        {finalContent}
      </Wrapper>

      {isDeactivated && (
        <DeactivatedWrapper>
          Deactivated&nbsp;
          <Tooltip
            openDelay={0}
            width={300}
            content="Deactivated as member count exceeds limit of your current plan."
          >
            <DeactivateIcon component={QuestionOutline} size={14} />
          </Tooltip>
        </DeactivatedWrapper>
      )}
    </Container>
  );
}

const Container = styled.div``;
const Wrapper = styled(Typography)`
  display: flex;
  align-items: baseline;
  word-break: break-word;
`;

const DeactivatedWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.alert};
  margin-top: 2px;

  ${({ theme }) => theme.font.caption};
`;

const DeactivateIcon = styled(Icon)`
  cursor: pointer;
  color: ${({ theme }) => getColorWithAlpha(theme.colors.alert, 0.5)};
  transition: color 0.2s;

  &:hover {
    color: ${({ theme }) => theme.colors.alert};
  }
`;
