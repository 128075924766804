import Router, { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  getColorWithAlpha,
  H1,
  P,
  useTheme,
  DisposableModal,
  DisposableModalAPI,
  MOBILE
} from '@elfsight-universe/ui-common';
import { useDealByAliasQuery } from '@api';
import { usePageContext, useUser } from '@modules/_app';
import { BLACK_FRIDAY_DEAL_ALIAS } from '@modules/deals/utils/deal-aliases';
import { WIDGET_INSTALL_PID_QUERY_PARAM } from '@modules/widget-install';
import { BlackFridayHeading } from '../black-friday-sale-page/components/black-friday-heading/black-friday-heading';
import backgroundPattern from './assets/black-friday-back-pattern.png';

const DISPOSABLE_INSTANCE_ID = 'black-friday-2024';

export function BlackFridayModal() {
  const { user } = useUser();
  const { offerModalHidden } = usePageContext();
  const disposableModalAPIRef = useRef<DisposableModalAPI | null>(null);
  const theme = useTheme();
  const { query } = useRouter();
  const { data: blackFridayDeal } = useDealByAliasQuery(
    { alias: BLACK_FRIDAY_DEAL_ALIAS },
    {
      enabled: !offerModalHidden
    }
  );

  const installParamValue = query[WIDGET_INSTALL_PID_QUERY_PARAM] as
    | string
    | undefined;

  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(
      !!blackFridayDeal?.accountDeal && !offerModalHidden && !installParamValue
    );
  }, [blackFridayDeal?.accountDeal, offerModalHidden, installParamValue]);

  const handleAction = () => {
    Router.push('/black-friday-sale');
    disposableModalAPIRef.current?.requestClose();
  };

  return (
    <DisposableModal
      maxWidth={460}
      closeColor={getColorWithAlpha(theme.colors.white, 0.5)}
      active={active}
      instanceId={DISPOSABLE_INSTANCE_ID}
      accountPid={user?.accountPid}
      containerComponent={StyledContainer}
      ref={disposableModalAPIRef}
    >
      <BlackFridayHeading headingLocation="modal" />

      <Content>
        <ContentScreamSale>50% OFF</ContentScreamSale>
        <ContentDescription>
          It&apos;s here - the biggest Elfsight sale. Don&apos;t miss it!
        </ContentDescription>
      </Content>

      <CenteredButton
        type="submit"
        onClick={handleAction}
        backgroundColor={theme.colors.brand}
        padding={80}
        fontWeight={600}
        large
      >
        Grab Deal
      </CenteredButton>
    </DisposableModal>
  );
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 60px 40px;
  background-color: ${({ theme }) => theme.colors.black};
  background-image: url(${backgroundPattern.src});
  background-size: 820px 225px;
  background-position: -180px -105.5px;
  background-repeat: no-repeat;
  border-radius: 8px;

  ${MOBILE} {
    padding: 60px 20px;
  }
`;

const Content = styled.div`
  text-align: center;
`;

const ContentScreamSale = styled(H1)`
  font-size: 60px;
  font-weight: 900;
  line-height: normal;
  color: ${({ theme }) => theme.colors.white};
`;
const ContentDescription = styled(P)`
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.white};
`;

const CenteredButton = styled(Button)`
  margin: 0 auto;
`;
