import { createContext, ReactNode, useContext, useRef } from 'react';
import { subscribeClientMessage } from '@api';
import { useEventBus } from '@modules/_event-bus';

export type IClientMessageContext = {
  reconnect: () => void;
  close: () => void;
};

export const ClientMessageContext = createContext<IClientMessageContext | null>(
  null
);

type ClientMessageProviderProps = {
  children: ReactNode;
};

export function ClientMessageProvider({
  children
}: ClientMessageProviderProps) {
  const eventBus = useEventBus();
  const sse = useRef<EventSource>();
  const start = () => {
    sse.current = subscribeClientMessage(eventBus.emit);
  };

  const close = () => {
    if (sse.current) {
      sse.current.close();
      sse.current = undefined;
    }
  };

  const reconnect = () => {
    close();
    start();
  };

  return (
    <ClientMessageContext.Provider value={{ reconnect, close }}>
      {children}
    </ClientMessageContext.Provider>
  );
}

export function useClientMessage() {
  const context = useContext(ClientMessageContext);

  if (context === null) {
    throw new Error(
      '`useClientMessage` must be nested inside an `AppSSEProvider`.'
    );
  }

  return context;
}
