import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import Script from 'next/script';
import { GA_DEBUG_VIEW_COOKIE_NAME } from '@elfsight-universe/service-core-contracts/analytics/cookie-name';
import { useUser } from '@modules/_app';

const GA_MEASUREMENT_ID =
  process.env.NEXT_PUBLIC_SERVICE_CORE__GA_MEASUREMENT_ID;

export function GoogleTagManager() {
  const [isLoaded, setLoaded] = useState(false);
  const [cookies] = useCookies();
  const isDebugView = Boolean(cookies[GA_DEBUG_VIEW_COOKIE_NAME]);
  const { user } = useUser();

  const handleLoad = () => {
    window.dataLayer = window.dataLayer || [];
    window.gtag = gtag;

    // eslint-disable-next-line
    function gtag(..._args: unknown[]) {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    }

    setLoaded(true);
  };

  useEffect(() => {
    if (!isLoaded) return;

    window.gtag('js', new Date());
    window.gtag('config', GA_MEASUREMENT_ID, {
      cookie_flags: 'SameSite=None;Secure',
      debug_mode: isDebugView,
      user_id: user?.pid
    });
  }, [user?.pid, isLoaded, isDebugView]);

  return (
    <>
      <Script
        src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
        onLoad={handleLoad}
        async
      />
    </>
  );
}
