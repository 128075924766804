import { useQuery } from '@tanstack/react-query';
import {
  GET_COUNT_PUBLIC_APPS_PATH,
  GetCountPublicAppsResponse
} from '@elfsight-universe/service-core-contracts/widget/get-count-public-apps';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useCountPublicAppsQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetCountPublicAppsResponse>(
    [GET_COUNT_PUBLIC_APPS_PATH],
    () => client.get(GET_COUNT_PUBLIC_APPS_PATH).json(),
    {
      enabled: isAuthenticated,
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  );
}
