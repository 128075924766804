import { useQuery } from '@tanstack/react-query';
import {
  GET_SWITCH_SUBSCRIPTION_PLAN_DETAILS_PATH,
  GetSwitchSubscriptionPlanDetailsRequest,
  GetSwitchSubscriptionPlanDetailsResponse,
  GetSwitchSubscriptionPlanDetailsResponseError
} from '@elfsight-universe/service-core-contracts/billing/get-switch-subscription-plan-details';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useSwitchSubscriptionPlanDetailsQuery({
  subscriptionPid,
  planPid
}: GetSwitchSubscriptionPlanDetailsRequest) {
  const { isAuthenticated } = useUser();

  return useQuery<
    GetSwitchSubscriptionPlanDetailsResponse,
    GetSwitchSubscriptionPlanDetailsResponseError
  >(
    [GET_SWITCH_SUBSCRIPTION_PLAN_DETAILS_PATH, planPid, subscriptionPid],
    () =>
      client
        .get(GET_SWITCH_SUBSCRIPTION_PLAN_DETAILS_PATH, {
          searchParams: { subscriptionPid, planPid }
        })
        .json<GetSwitchSubscriptionPlanDetailsResponse>(),
    {
      enabled: Boolean(planPid && subscriptionPid) && isAuthenticated
    }
  );
}
