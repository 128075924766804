import debounce from 'lodash/debounce';
import {
  SEND_ANALYTICS_EVENT_PATH,
  SendAnalyticsEventRequest
} from '@elfsight-universe/service-core-contracts/analytics/send-analytics-event';
import { parseBool } from '@utils';
import { client } from '../client';

const { NEXT_PUBLIC_ANALYTICS_ENABLED } = process.env;

export const sendAnalyticsEvent = (data: SendAnalyticsEventRequest) => {
  if (!parseBool(NEXT_PUBLIC_ANALYTICS_ENABLED)) return;

  return client
    .post(SEND_ANALYTICS_EVENT_PATH, {
      json: data
    })
    .catch(() => {
      console.log('catch analytics event', data);
      window.gtag && window.gtag('event', data);
    });
};

export const debouncedSendAnalyticsEvent = debounce(sendAnalyticsEvent, 1000);
