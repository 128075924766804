import { Transform } from 'class-transformer';
import { IsString } from 'class-validator';
import { JsonObject, JsonValue } from '../../json.type';
import { PaddleHighRiskTransactionStatus } from '../enums';
import { PaddleAlertName } from '../enums/paddle-alert-name';
import type { PaddlePassthrough } from '../interfaces/paddle-passthrough';
import { toJSON } from '../../utils/to-json';

export class HighRiskTransactionCreatedRequest implements JsonObject {
  [K: string]: JsonValue;

  @IsString()
  alert_id: string;

  @IsString()
  alert_name: PaddleAlertName;

  @IsString()
  case_id: string;

  @IsString()
  checkout_id: string;

  @IsString()
  created_at: string;

  @IsString()
  customer_email_address: string;

  @IsString()
  customer_user_id: string;

  @IsString()
  event_time: string;

  @IsString()
  marketing_consent: string;

  @Transform(({ value }) => toJSON(value))
  passthrough: PaddlePassthrough;

  @IsString()
  product_id: string;

  @IsString()
  status: PaddleHighRiskTransactionStatus;

  @IsString()
  p_signature: string;
}
