import { Exclude, Expose, Type } from 'class-transformer';
import { IsIn, IsOptional, IsString } from 'class-validator';
import { PaginatedRequest } from '../paginated-request';
import { PaginationResponse } from '../pagination-response';
import { WidgetStatus } from './enums';

export const GET_ALL_WIDGETS_PATH = 'widget/get-all-widgets';

export class GetAllWidgetsRequest extends PaginatedRequest {
  @IsIn(['name', 'appName', 'updatedAt'])
  @IsOptional()
  orderBy: 'name' | 'appName' | 'updatedAt' = 'updatedAt';

  @IsIn(['asc', 'desc'])
  @IsOptional()
  order: 'asc' | 'desc' = 'desc';

  @IsString()
  @IsOptional()
  appAlias?: string;

  @IsString()
  @IsOptional()
  name?: string;
}

export type GetAllWidgetsRequestOrderableProperty =
  | 'name'
  | 'appName'
  | 'updatedAt';

@Exclude()
export class GetAllWidgetsResponse {
  @Expose()
  @Type(() => PaginationResponse)
  meta: PaginationResponse;

  @Expose()
  @Type(() => GetAllWidgetsResponseWidget)
  payload: GetAllWidgetsResponseWidget[];
}

@Exclude()
export class GetAllWidgetsResponseWidget {
  @Expose()
  pid: string;

  @Expose()
  updatedAt: Date;

  @Expose()
  name: string;

  @Expose()
  status: WidgetStatus;

  @Expose()
  isLiteSubscription: boolean;

  @Expose()
  isLiteSubscriptionConfirmed: boolean;

  @Expose()
  isDraft: boolean;

  @Expose()
  @Type(() => GetAllWidgetsResponseWidgetApp)
  app: {
    name: string;
    icon: string;
    alias: string;
    category: {
      color: string;
    };
    canExportData: boolean;
  };

  // @TODO remove IMPORT TEMP flag
  @Expose()
  disabled: boolean;
}

@Exclude()
export class GetAllWidgetsResponseWidgetApp {
  @Expose()
  name: string;

  @Expose()
  icon: string;

  @Expose()
  alias: string;

  @Expose()
  canExportData: boolean;

  @Expose()
  @Type(() => GetAllWidgetsResponseWidgetAppCategory)
  category: {
    color: string;
  };
}

@Exclude()
export class GetAllWidgetsResponseWidgetAppCategory {
  @Expose()
  color: string;
}
