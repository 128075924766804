import styled from 'styled-components';
import { QuestionOutline } from '@elfsight/icons';
import { Icon } from '../icons';
import { ThemeColor } from '../../theme';
import { getColorWithAlpha } from '../../utils';
import { Tooltip, TooltipProps } from './tooltip';

export interface HintProps {
  iconColor?: ThemeColor;
  iconSize?: number;
}

export function Hint({
  openDelay = 0,
  closeDelay = 0,
  iconColor = 'black',
  iconSize = 14,
  width = 240,
  ...forwardingProps
}: Omit<TooltipProps, 'children'> & HintProps) {
  return (
    <Tooltip
      closeDelay={closeDelay}
      openDelay={openDelay}
      width={width}
      {...forwardingProps}
    >
      <StyledIcon
        component={QuestionOutline}
        size={iconSize}
        color={iconColor}
      />
    </Tooltip>
  );
}

const StyledIcon = styled(Icon)<{ color: ThemeColor }>`
  position: relative;
  vertical-align: text-bottom;
  display: inline-block;
  cursor: help;

  fill: ${({ color, theme }) => {
    if (color === 'black') {
      return theme.colors.gray50;
    }

    if (color === 'white') {
      return getColorWithAlpha('white', 0.5);
    }

    return theme.colors[color];
  }};

  ::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    transform: translate(-50%, -50%);
  }

  :hover {
    fill: ${({ color, theme }) => theme.colors[color]};
  }
`;
