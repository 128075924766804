import { createContext, PropsWithChildren, useContext } from 'react';
import {
  SortWidgetsVariant,
  ViewWidgetsVariant
} from '@elfsight-universe/service-core-contracts/widget';
import { useUserPreferencesQuery } from '@api';

export type IUserPreferencesContext = {
  viewsLimitNotification?: boolean;
  viewWidgetsVariant?: ViewWidgetsVariant | null;
  sortWidgetsVariant?: SortWidgetsVariant | null;
  isLoading: boolean;
};

export const UserPreferencesContext =
  createContext<IUserPreferencesContext | null>(null);

export function UserPreferencesProvider({ children }: PropsWithChildren) {
  const { data: userPreferences, isLoading } = useUserPreferencesQuery();

  return (
    <UserPreferencesContext.Provider
      value={{
        viewsLimitNotification: userPreferences?.viewsLimitNotification,
        viewWidgetsVariant: userPreferences?.viewWidgetsVariant,
        sortWidgetsVariant: userPreferences?.sortWidgetsVariant,
        isLoading
      }}
    >
      {children}
    </UserPreferencesContext.Provider>
  );
}

export function useUserPreferencesContext() {
  const context = useContext(UserPreferencesContext);

  if (context === null) {
    throw new Error(
      '`useUserPreferencesContext` must be nested inside an `UserPreferencesProvider`.'
    );
  }

  return context;
}
