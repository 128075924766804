import { MouseEvent } from 'react';
import styled, { useTheme } from 'styled-components';
import useCopyClipboard from 'react-use-clipboard';

import {
  Button,
  Countdown,
  Link,
  Popover,
  ThemeColor
} from '@elfsight-universe/ui-common';
import { helpscoutArticles } from '@constants';
import { usePageContext } from '@modules/_app';
import { beacon } from '../../beacon';
import { OfferSlideIn, OfferSlideInProps } from './offer-slide-in';

export type OfferCouponSlideInProps = OfferSlideInProps & {
  coupon?: string;
  expiredAt?: Date | null;
  couponColor?: ThemeColor;
  couponBorderColor?: ThemeColor;
  offerType?: OfferCouponSlideInType;
  buttonText?: string;
  buttonColor?: ThemeColor;
  buttonLink?: string;
  width?: number;
};

export enum OfferCouponSlideInType {
  BUTTON = 'BUTTON',
  COUPON = 'COUPON'
}

export function OfferCouponSlideIn({
  coupon,
  expiredAt,
  offerType = OfferCouponSlideInType.COUPON,
  couponColor = 'promoTransparent',
  couponBorderColor = 'promo',
  buttonColor = 'brand',
  buttonLink,
  buttonText,
  ...forwardingProps
}: OfferCouponSlideInProps) {
  const [isCopied, copyTextToClipboard] = useCopyClipboard(coupon || '', {
    successDuration: 1000
  });
  const { zIndex } = useTheme();
  const { override } = usePageContext();

  const openBeaconArticle =
    (articleId: string) => (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      override({ beaconHidden: false });
      beacon.article(articleId);
    };

  return (
    <OfferSlideIn {...forwardingProps}>
      <Actions>
        {
          {
            [OfferCouponSlideInType.COUPON]: (
              <Popover
                isOpen={isCopied}
                placement="top"
                containerStyle={{
                  zIndex: zIndex.coupon.toString()
                }}
                content={<Copied>Copied</Copied>}
                preventFocus
              >
                <Coupon
                  _couponBorderColor={couponBorderColor}
                  _couponColor={couponColor}
                  onClick={copyTextToClipboard}
                >
                  {coupon}
                </Coupon>
              </Popover>
            ),
            [OfferCouponSlideInType.BUTTON]: (
              <Button backgroundColor={buttonColor} href={buttonLink} small>
                {buttonText}
              </Button>
            )
          }[offerType]
        }

        {expiredAt && <Countdown expiredAt={expiredAt} color="black" />}
      </Actions>

      {
        {
          [OfferCouponSlideInType.COUPON]: (
            <Link
              color="accent"
              onClick={openBeaconArticle(helpscoutArticles.coupon.id)}
            >
              How to use coupon
            </Link>
          ),
          [OfferCouponSlideInType.BUTTON]: <></>
        }[offerType]
      }
    </OfferSlideIn>
  );
}

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 8px;
`;

const Coupon = styled.div<{
  _couponColor: ThemeColor;
  _couponBorderColor: ThemeColor;
}>`
  ${({ theme }) => theme.font.title4Semibold};
  background: ${({ theme, _couponColor }) => theme.colors[_couponColor]};
  border: 1px dashed
    ${({ theme, _couponBorderColor }) => theme.colors[_couponBorderColor]};
  box-sizing: border-box;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  height: 36px;
  padding: 0 12px;
`;

const Copied = styled.div`
  ${({ theme }) => theme.font.caption};
  padding: 6px 8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.black};
  color: ${({ theme }) => theme.colors.white};
`;
