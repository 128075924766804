import { Exclude, Expose, Type } from 'class-transformer';
import { IsUUID } from 'class-validator';
import { BillingError } from '../errors';
import {
  BillingInterval,
  PlanGrade,
  PlanType,
  SubscriptionStatus
} from './enums';
import { PlanPolicy, PlanPolicyModifier } from './classes';
import { getTransformClassForPlanProps } from './utils';

export const GET_SUBSCRIPTION_PATH = 'billing/get-subscription';

export class GetSubscriptionRequest {
  @IsUUID()
  pid: string;
}

@Exclude()
export class GetSubscriptionResponse {
  @Expose()
  pid: string;

  @Expose()
  createdAt: string;

  @Expose()
  updatedAt: string;

  @Expose()
  status: SubscriptionStatus;

  @Expose()
  cancelledAt: string;

  // @TODO add types
  @Expose()
  data: any;

  @Expose()
  @Type(() => GetSubscriptionResponsePlan)
  plan: {
    pid: string;
    createdAt: string;
    updatedAt: string;
    type: PlanType;
    properties:
      | {
          grade: PlanGrade;
          billingInterval: BillingInterval;
          price: number;
          icon?: string;
        }
      | object;
  };

  @Expose()
  @Type(() => GetSubscriptionResponseSubscriptionCancellation)
  subscriptionCancellation: {
    createdAt: Date;
    executeAfter: Date;
    executedAt: Date | null;
    details: string | null;
  } | null;

  @Expose()
  @Type(() => PlanPolicyModifier)
  planPolicyModifiers: PlanPolicyModifier[];

  @Expose()
  @Type(() => PlanPolicy)
  effectivePlanPolicy: {
    viewsLimit: number | null;
    widgetsLimit: number;
    projectsLimit: number;
    collaboratorsLimit: number;
  };

  @Expose()
  canScheduleSubscriptionCancellation: boolean;
}

@Exclude()
export class GetSubscriptionResponsePlan {
  @Expose()
  pid: string;

  @Expose()
  createdAt: string;

  @Expose()
  updatedAt: string;

  @Expose()
  type: PlanType;

  @Expose()
  @Type(getTransformClassForPlanProps)
  properties:
    | {
        grade: PlanGrade;
        billingInterval: BillingInterval;
        price: number;
        icon?: string;
      }
    | object;

  @Expose()
  @Type(() => PlanPolicy)
  policy: {
    viewsLimit: number | null;
    widgetsLimit: number;
    projectsLimit: number;
    collaboratorsLimit: number;
  };

  @Expose()
  name: string;

  @Expose()
  active: boolean;
}

@Exclude()
export class GetSubscriptionResponseSubscriptionCancellation {
  @Expose()
  createdAt: Date;

  @Expose()
  executeAfter: Date;

  @Expose()
  executedAt: Date | null;

  @Expose()
  details: string | null;
}

export type GetSubscriptionResponseError =
  BillingError.SUBSCRIPTION_DOES_NOT_EXIST;
