import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_SHARED_WIDGET_ADDITIONAL_INFO_PATH,
  GetSharedWidgetAdditionalInfoResponse,
  GetSharedWidgetAdditionalInfoResponseError
} from '@elfsight-universe/service-core-contracts/widget';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useSharedWidgetAdditionalInfoQuery(
  pid: string | undefined,
  {
    enabled,
    ...options
  }: UseQueryOptions<
    GetSharedWidgetAdditionalInfoResponse,
    GetSharedWidgetAdditionalInfoResponseError
  > = {}
) {
  const { isAuthenticated } = useUser();
  return useQuery<
    GetSharedWidgetAdditionalInfoResponse,
    GetSharedWidgetAdditionalInfoResponseError
  >(
    [GET_SHARED_WIDGET_ADDITIONAL_INFO_PATH, pid],
    () =>
      client
        .get(GET_SHARED_WIDGET_ADDITIONAL_INFO_PATH, {
          searchParams: { widgetPid: pid as string }
        })
        .json(),
    {
      enabled:
        (enabled === undefined || enabled) && Boolean(pid) && isAuthenticated,
      ...options
    }
  );
}
