import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import {
  DECLINE_PROJECT_INVITATION_PATH,
  DeclineProjectInvitationRequest,
  DeclineProjectInvitationResponseError
} from '@elfsight-universe/service-core-contracts/project';
import { client } from '../client';

export function useDeclineProjectInvitationMutation(
  options: UseMutationOptions<
    undefined,
    DeclineProjectInvitationResponseError,
    DeclineProjectInvitationRequest
  > = {}
) {
  return useMutation<
    undefined,
    DeclineProjectInvitationResponseError,
    DeclineProjectInvitationRequest
  >(
    ({ projectInvitationPid }) =>
      client
        .post(DECLINE_PROJECT_INVITATION_PATH, {
          json: { projectInvitationPid }
        })
        .json<undefined>(),
    {
      retry: false,
      ...options
    }
  );
}
