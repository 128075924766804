import Router, { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  H1,
  P,
  useTheme,
  DisposableModal,
  DisposableModalAPI
} from '@elfsight-universe/ui-common';
import { useDealByAliasQuery } from '@api';
import { usePageContext, useUser } from '@modules/_app';
import { BIRTHDAY_DEAL_ALIAS } from '@modules/deals/utils/deal-aliases';
import { WIDGET_INSTALL_PID_QUERY_PARAM } from '@modules/widget-install';
import { Heading } from '../birthday-sale-page/components/heading';
import BackgroundSVG from './assets/birthday-modal-background.svg';

const DISPOSABLE_INSTANCE_ID = 'birthday-sale-2024';

export function BirthdayModal() {
  const { user } = useUser();
  const { offerModalHidden } = usePageContext();
  const disposableModalAPIRef = useRef<DisposableModalAPI | null>(null);
  const theme = useTheme();
  const { query } = useRouter();
  const { data: dealData } = useDealByAliasQuery(
    { alias: BIRTHDAY_DEAL_ALIAS },
    {
      enabled: !offerModalHidden
    }
  );

  const installParamValue = query[WIDGET_INSTALL_PID_QUERY_PARAM] as
    | string
    | undefined;

  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(
      !!dealData?.accountDeal && !offerModalHidden && !installParamValue
    );
  }, [dealData?.accountDeal, offerModalHidden, installParamValue]);

  const handleAction = () => {
    Router.push('/birthday-sale');
    disposableModalAPIRef.current?.requestClose();
  };

  return (
    <DisposableModal
      maxWidth={460}
      ref={disposableModalAPIRef}
      active={active}
      instanceId={DISPOSABLE_INSTANCE_ID}
      accountPid={user?.accountPid}
      containerComponent={StyledContainer}
    >
      <Heading />

      <Content>
        <ContentScreamSale>33% OFF</ContentScreamSale>
        <ContentDescription>Get your spot at our party!</ContentDescription>
      </Content>

      <CenteredButton
        type="submit"
        onClick={handleAction}
        backgroundColor={theme.colors.brand}
        padding={20}
        fontWeight={600}
        large
        center
      >
        Grab Deal
      </CenteredButton>
      <BackgroundConfetti />
    </DisposableModal>
  );
}

const StyledContainer = styled.div`
  max-height: 100%;
  display: flex;
  flex-direction: column;
  padding: 60px 40px;
  border-radius: 8px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #ffffff 0%,
    #fcfaff 55.87%,
    #f5edff 100%
  );
`;

const Content = styled.div`
  margin: 48px 0 40px;
  text-align: center;
`;

const ContentScreamSale = styled(H1)`
  font-size: 60px;
  font-weight: 900;
  line-height: normal;
  color: ${({ theme }) => theme.colors.brand};
`;

const ContentDescription = styled(P)`
  ${({ theme }) => theme.font.title3};
`;

const CenteredButton = styled(Button)`
  margin: 0 auto;
  width: 200px;
`;

const BackgroundConfetti = styled(BackgroundSVG)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;
