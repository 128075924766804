import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_PROJECT_BY_PID_PATH,
  GetProjectByPidError,
  GetProjectByPidResponse
} from '@elfsight-universe/service-core-contracts/project';
import { useUser } from '@modules/_app';
import { useEvents } from '@modules/_event-bus';
import { catchUnhandledError } from '@modules/_error';
import { client } from '../client';

export function useProjectByPidQuery(
  pid: string | undefined,
  options: UseQueryOptions<GetProjectByPidResponse, GetProjectByPidError> = {}
) {
  const { isAuthenticated } = useUser();

  const query = useQuery<GetProjectByPidResponse, GetProjectByPidError>(
    [GET_PROJECT_BY_PID_PATH, pid],
    () =>
      client
        .get(GET_PROJECT_BY_PID_PATH, {
          searchParams: { pid: pid as string }
        })
        .json<GetProjectByPidResponse>(),
    {
      enabled: Boolean(pid) && isAuthenticated,
      retry: false,
      onError: (error) => {
        catchUnhandledError(error, ['PROJECT_DOES_NOT_EXIST']);
      },
      ...options
    }
  );

  useEvents(['ProjectThumbnailCreated'], () => query.refetch());

  return query;
}
