import { UIEvent } from 'react';
import styled, { css } from 'styled-components';
import { toast } from 'react-hot-toast';
import { WarningFilled } from '@elfsight/icons';
import { Callout, Icon, Link } from '@elfsight-universe/ui-common';
import { useEmailResendConfirmMutation } from '@api';
import { useUser } from '@modules/_app';

type ResendConfirmEmailCalloutProps = {
  margin?: number | [number, number];
};

export function ResendConfirmEmailCallout({
  margin
}: ResendConfirmEmailCalloutProps) {
  const { user } = useUser();
  const {
    mutate: requestUserVerification,
    isSuccess: isBeginVerificationSuccess
  } = useEmailResendConfirmMutation({
    onSuccess: () => {
      toast.success('Follow the link in the confirmation email we sent.');
    }
  });

  if (!user || user?.verified) {
    return null;
  }

  function handleLinkClick(event: UIEvent) {
    event.preventDefault();
    requestUserVerification();
  }

  const link = !isBeginVerificationSuccess && (
    <Link underline onClick={handleLinkClick}>
      Resend
    </Link>
  );

  return (
    <Container _margin={margin}>
      <Callout
        icon={<Icon size={16} component={WarningFilled} fill="warning" />}
        backgroundColor="warning"
      >
        Your email is not confirmed. Follow the link in the confirmation email
        we sent. {link}
      </Callout>
    </Container>
  );
}

const Container = styled.div<{
  _margin?: number | [number, number];
}>`
  width: 100%;
  max-width: 900px;

  ${({ _margin = [0, 16] }) => {
    const [mt, mb] = Array.isArray(_margin) ? _margin : [_margin, _margin];
    return css`
      margin-top: ${mt}px;
      margin-bottom: ${mb}px;
    `;
  }}
`;

export const Resend = styled.span`
  text-decoration: underline;

  :hover {
    cursor: pointer;
  }
`;
