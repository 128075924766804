import { useState } from 'react';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { BEACON_ID } from '@constants';
import { usePageContext } from '@modules/_app';
import { useImpersonateSession } from '@modules/impersonate-session';
import { BeaconStyleOverride } from '@modules/beacon/beacon-style-override';
import { BeaconSubscriptions } from '@modules/beacon/beacon-subscriptions';
import { beacon } from './beacon';

const BEACON_QUEUE_HACK = `
  window.Beacon = window.Beacon || function queueHandler(method, options, data) {
    window.Beacon.readyQueue.push({ method, options, data });
  };
  window.Beacon.readyQueue = [];
`;

const BEACON_VENDOR_URL = 'https://beacon-v2.helpscout.net';

export function BeaconProvider() {
  const {
    beaconHidden,
    beaconPosition,
    beaconID = BEACON_ID
  } = usePageContext();
  const { isInImpersonateSession } = useImpersonateSession();

  const { query } = useRouter();
  const queryHideBeacon = query.hideBeacon === 'true';

  const [isLoaded, setLoaded] = useState(false);

  const handleLoad = () => {
    setLoaded(true);
    handleInteracting();
  };

  return (
    <>
      <BeaconStyleOverride _position={beaconPosition} />

      <Script id="helpscoutBeaconQueue" strategy="afterInteractive">
        {BEACON_QUEUE_HACK}
      </Script>

      <Script
        id="helpscoutBeacon"
        src={BEACON_VENDOR_URL}
        strategy="lazyOnload"
        onLoad={handleLoad}
        async
      />

      {isLoaded && (
        <BeaconSubscriptions
          hidden={beaconHidden || isInImpersonateSession || queryHideBeacon}
          id={beaconID}
          position={beaconPosition}
        />
      )}
    </>
  );
}

const handleInteracting = () => {
  const startInteracting = () => {
    beacon.setState({ interacting: true });
  };
  const stopInteracting = () => {
    beacon.setState({ interacting: false });
  };

  beacon.on('message-triggered', startInteracting);
  beacon.on('open', startInteracting);
  beacon.on('message-closed', stopInteracting);
  beacon.on('close', stopInteracting);
};
