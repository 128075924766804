import { forwardRef, InputHTMLAttributes, MouseEvent, useRef } from 'react';
import styled from 'styled-components';
import { mergeRefs } from 'react-merge-refs';
import { Close16, Search16 } from '@elfsight/icons';
import { Icon } from '../icons';
import { ButtonPrimitive } from '../buttons';
import { Control } from './control';
import { ControlInner, ControlInput } from './control-layout';
import { ControlProps, ControlSize } from './control-types';
import { useFocused } from './use-focused';

export type SearchInputProps = InputHTMLAttributes<HTMLInputElement> &
  ControlProps<{
    onReset?: () => void;
  }>;

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  function _SearchInput(
    {
      value,
      large,
      fullWidth,
      maxWidth,
      onReset,
      className,
      placeholder = 'Search',
      onFocus,
      onBlur,
      ...forwardingProps
    },
    forwardingRef
  ) {
    const [focused, focusHandlers] = useFocused(onFocus, onBlur);
    const inputRef = useRef<HTMLInputElement>(null);
    const mergedRef = mergeRefs([inputRef, forwardingRef]);

    const size: ControlSize = large ? 'large' : 'medium';
    const fontSize = { large: 16, medium: 14 }[size];
    const height = { large: 48, medium: 36 }[size];

    const handleReset = (e: MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (onReset) {
        onReset();
      }
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    const handleFocus = () => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    };

    return (
      <StyledControl
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        fontSize={fontSize}
        height={height}
        padding={0}
        focused={focused}
        className={className}
        onClick={handleFocus}
      >
        <SearchIcon component={Search16} />

        <ControlInput
          role="search"
          value={value}
          ref={mergedRef}
          placeholder={placeholder ? `${placeholder}…` : undefined}
          {...focusHandlers}
          {...forwardingProps}
        />

        {onReset && value && (
          <ResetButton onClick={handleReset}>
            <Icon component={Close16} size={12} fill="currentColor" />
          </ResetButton>
        )}
      </StyledControl>
    );
  }
);

const StyledControl = styled(Control)`
  ${ControlInner} {
    padding: 0 32px 0 8px;
  }
`;

const ResetButton = styled(ButtonPrimitive)`
  position: absolute;
  top: 8px;
  right: 8px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  padding: 4px;
  border: none;
  outline: none;
  z-index: 3;
  transition: all 0.2s;

  color: ${({ theme }) => theme.colors.gray50};
  background: ${({ theme }) => theme.colors.gray5};

  :hover,
  :active,
  :focus {
    color: ${({ theme }) => theme.colors.black};
    background: ${({ theme }) => theme.colors.gray10};
  }
`;

const SearchIcon = styled(Icon).attrs({
  size: 16,
  fill: 'gray30'
})`
  margin-right: 8px;
`;
