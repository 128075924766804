import {
  ValidatorConstraint,
  ValidatorConstraintInterface
} from 'class-validator';

@ValidatorConstraint({ async: false })
export class FieldContainsDoneOrAccessDetails
  implements ValidatorConstraintInterface
{
  validate(text: string) {
    return text.trim().toLowerCase().includes('done') || text.trim().length > 4;
  }

  defaultMessage() {
    return 'Please provide access details.';
  }
}
