import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_SUBSCRIPTION_PATH,
  GetSubscriptionResponse,
  GetSubscriptionResponseError
} from '@elfsight-universe/service-core-contracts/billing/get-subscription';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useSubscriptionQuery(
  pid: string | undefined,
  options: Pick<
    UseQueryOptions<GetSubscriptionResponse, GetSubscriptionResponseError>,
    'onError' | 'onSuccess'
  >
) {
  const { isAuthenticated } = useUser();
  return useQuery<GetSubscriptionResponse, GetSubscriptionResponseError>(
    [GET_SUBSCRIPTION_PATH, pid],
    () =>
      client
        .get(GET_SUBSCRIPTION_PATH, {
          searchParams: { pid: pid as string }
        })
        .json(),
    {
      enabled: Boolean(pid) && isAuthenticated,
      ...options
    }
  );
}
