import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_PROJECTS_USAGE_PATH,
  GetProjectsUsageResponse
} from '@elfsight-universe/service-core-contracts/project';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useProjectsUsageQuery({
  enabled = true,
  ...options
}: UseQueryOptions<GetProjectsUsageResponse> = {}) {
  const { isAuthenticated } = useUser();
  return useQuery<GetProjectsUsageResponse>(
    [GET_PROJECTS_USAGE_PATH],
    () => client.get(GET_PROJECTS_USAGE_PATH).json<GetProjectsUsageResponse>(),
    {
      enabled: enabled && isAuthenticated,
      ...options
    }
  );
}
