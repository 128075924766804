import styled from 'styled-components';
import { Loader, TabPanel } from '@elfsight-universe/ui-common';
import { LayoutInner, LayoutSection } from '@components';
import { useUpgradeContext } from '../upgrade-context';
import { PlanTab } from '../constants';
import { PlansSingle } from './plans-single';
import { PlansPack } from './plans-pack';

export function PlansSection() {
  const { data, isLoading } = useUpgradeContext();

  return (
    <LayoutSection
      _withBackground
      _minHeight={470}
      _padding={[32, 40]}
      _paddingMobile={[24, 40]}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <LayoutInner _width={1000}>
          {data?.displaySingleAppPlans && (
            <TabPanel tabID={PlanTab.SINGLE}>
              <Container>
                <PlansSingle />
              </Container>
            </TabPanel>
          )}

          {data?.displayAllAppsPackPlans && (
            <TabPanel tabID={PlanTab.PACKS}>
              <Container>
                <PlansPack />
              </Container>
            </TabPanel>
          )}
        </LayoutInner>
      )}
    </LayoutSection>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
