import styled from 'styled-components';
import { toast } from 'react-hot-toast';
import { ArrowBoldRight20 } from '@elfsight/icons';
import {
  TabPanel,
  TabProps,
  P,
  Button,
  Icon,
  Loader,
  AppIcon
} from '@elfsight-universe/ui-common';
import { useWidgetQuery } from '@api';
import { useWixIntegration } from '@modules/wix-integration/contexts/wix-integration';
import WixIcon from '@icons/wix.svg';

type EmbedToWebsiteTabProps = Omit<TabProps, 'children'> & {
  widgetPid: string;
};

export function EmbedToWixTab({ tabID, widgetPid }: EmbedToWebsiteTabProps) {
  const { data: widgetData, isLoading: isWidgetQueryLoading } =
    useWidgetQuery(widgetPid);
  const wixIntegration = useWixIntegration();
  const isCurrentWidgetConnected =
    wixIntegration.isWixSession &&
    wixIntegration.isWixWidgetConnectedTo(widgetPid);
  const isConnecting =
    wixIntegration.isWixSession && wixIntegration.isConnecting;
  function handleEmbedWidgetButtonClick() {
    if (!wixIntegration.isWixSession) {
      return;
    }
    wixIntegration.connectWixWidget(widgetPid, () => {
      toast.success(
        `Widget "${widgetData?.name}" has been successfully installed on your Wix website.`
      );
    });
  }

  return (
    <TabPanel tabID={tabID}>
      {isWidgetQueryLoading ? (
        <Loader />
      ) : (
        <InnerWrapper>
          <IconsWrapper>
            <AppIcon icon={widgetData?.app.icon} size={48} />

            <Icon component={ArrowBoldRight20} size={24} fill="gray30" />
            <Icon component={WixIcon} size={44} fill="black" />
          </IconsWrapper>

          {isCurrentWidgetConnected ? (
            <P mb={20} mt={20} alignCenter>
              Widget is installed on your Wix website.
            </P>
          ) : (
            <>
              {' '}
              <P mb={20} mt={20} alignCenter>
                Click the button below to embed the widget to your Wix website.
              </P>
              <Button
                variation="accentPrimary"
                onClick={handleEmbedWidgetButtonClick}
                isLoading={isConnecting}
              >
                Embed Widget
              </Button>
            </>
          )}
        </InnerWrapper>
      )}
    </TabPanel>
  );
}

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 28px;
`;

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;
