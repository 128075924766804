import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import pickBy from 'lodash/pickBy';
import {
  GET_WIDGETS_LIST_PATH,
  GetWidgetsListRequest,
  GetWidgetsListResponse
} from '@elfsight-universe/service-core-contracts/widget/get-widgets-list';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useWidgetsListQuery(
  dto: GetWidgetsListRequest,
  { enabled = true, ...options }: UseQueryOptions<GetWidgetsListResponse> = {}
) {
  const { isAuthenticated } = useUser();
  return useQuery<GetWidgetsListResponse>(
    [GET_WIDGETS_LIST_PATH, ...Object.values(dto)],
    () =>
      client
        .get(GET_WIDGETS_LIST_PATH, {
          searchParams: pickBy({ ...dto }, (v) => v !== undefined)
        })
        .json<GetWidgetsListResponse>(),
    {
      keepPreviousData: true,
      enabled: enabled && isAuthenticated,
      ...options
    }
  );
}
