import { useState } from 'react';
import styled from 'styled-components';
import {
  Badge,
  IconButton,
  Popover,
  Tooltip
} from '@elfsight-universe/ui-common';
import { formatBadgeNumber } from '@modules/_header/utils';
import Megaphone from '@icons/megaphone.svg';
import { useAnnouncementsContext } from './announcements-provider';
import { AnnouncementsContent } from './announcements-content';

export function AnnouncementsPopover() {
  const { data, numberOfUnreadAnnouncements, isOpen, toggleOpen } =
    useAnnouncementsContext();

  const badgeText = formatBadgeNumber(numberOfUnreadAnnouncements);

  const [popoverHeight, setPopoverHeight] = useState(0);

  return (
    <Popover
      isOpen={isOpen}
      onOpenChange={toggleOpen}
      content={
        <Content>
          <AnnouncementsContent
            onCloseButtonClick={() => toggleOpen(false)}
            withClose
            maxHeight={popoverHeight}
          />
        </Content>
      }
      preventFocus
      maxHeight={660}
      getPopoverHeight={setPopoverHeight}
      floatingStrategy="fixed"
      focusManagerProps={{
        modal: true
      }}
    >
      <Tooltip content="What’s New" preventOpen={isOpen}>
        <PopoverTrigger>
          <IconButton
            active={isOpen}
            icon={Megaphone}
            onClick={() => toggleOpen()}
          />
          {data && badgeText && <StyledBadge>{badgeText}</StyledBadge>}
        </PopoverTrigger>
      </Tooltip>
    </Popover>
  );
}

const PopoverTrigger = styled.div`
  position: relative;
`;

const Content = styled.div`
  max-width: 360px;
  overflow: hidden;
`;

const StyledBadge = styled(Badge)`
  pointer-events: none;
`;
