import { Transform } from 'class-transformer';
import { IsString } from 'class-validator';
import { JsonObject, JsonValue } from '../../json.type';
import type { PaddlePassthrough } from '../interfaces/paddle-passthrough';
import { PaddleAlertName } from '../enums/paddle-alert-name';
import { PaddleSubscriptionStatus } from '../enums/paddle-subscription-status.enum';
import { BillingError } from '../../errors/billing-error.enum';
import { toJSON } from '../../utils/to-json';

export class SubscriptionPaymentSucceededRequest implements JsonObject {
  [K: string]: JsonValue;

  @IsString()
  alert_id: string;

  @IsString()
  alert_name: PaddleAlertName;

  @IsString()
  balance_currency: string;

  @IsString()
  balance_earnings: string;

  @IsString()
  balance_fee: string;

  @IsString()
  balance_gross: string;

  @IsString()
  balance_tax: string;

  @IsString()
  checkout_id: string;

  @IsString()
  country: string;

  @IsString()
  coupon: string;

  @IsString()
  currency: string;

  @IsString()
  customer_name: string;

  @IsString()
  earnings: string;

  @IsString()
  email: string;

  @IsString()
  event_time: string;

  @IsString()
  fee: string;

  @IsString()
  initial_payment: string;

  @IsString()
  instalments: string;

  @IsString()
  marketing_consent: string;

  @IsString()
  next_bill_date: string;

  @IsString()
  next_payment_amount: string;

  @IsString()
  order_id: string;

  @Transform(({ value }) => toJSON(value))
  passthrough: PaddlePassthrough;

  @IsString()
  payment_method: string;

  @IsString()
  payment_tax: string;

  @IsString()
  plan_name: string;

  @IsString()
  quantity: string;

  @IsString()
  receipt_url: string;

  @IsString()
  sale_gross: string;

  @IsString()
  status: PaddleSubscriptionStatus;

  @IsString()
  subscription_id: string;

  @IsString()
  subscription_payment_id: string;

  @IsString()
  subscription_plan_id: string;

  @IsString()
  unit_price: string;

  @IsString()
  user_id: string;

  @IsString()
  p_signature: string;
}
export type SubscriptionPaymentSucceededResponseError =
  BillingError.SUBSCRIPTION_DOES_NOT_EXIST;
