import { useState } from 'react';
import styled, { css } from 'styled-components';
import { ButtonPrimitive } from '@elfsight-universe/ui-common/src/components/buttons';
import { Chip } from '@elfsight-universe/ui-common';
import { useUpgradeContext } from '@modules/upgrade/upgrade-context';
import { PricingTableColumn } from './pricing-table-column';
import { PricingTablePlan, PricingTableProps } from './pricing-table-types';

export type PricingTableMobileViewProps = Pick<
  PricingTableProps,
  'billingInterval' | 'colorTheme'
> & {
  plans: PricingTablePlan[];
};

export function PricingTableMobileView({
  plans,
  billingInterval,
  colorTheme
}: PricingTableMobileViewProps) {
  // const { isWelcomeDeal } = useUpgradeContext();
  const [activePlanIndex, setActivePlanIndex] = useState(0);

  return (
    <Container>
      <Selector>
        {plans.map(({ name, highlight }, i) => (
          <Button
            key={name}
            onClick={() => setActivePlanIndex(i)}
            _active={activePlanIndex === i}
          >
            {name}
            {/*{highlight && !isWelcomeDeal && (*/}
            {/*  <Badge*/}
            {/*    textColor="black"*/}
            {/*    backgroundColor="#ffec00"*/}
            {/*    backgroundColorOpacity={1}*/}
            {/*  >*/}
            {/*    Popular*/}
            {/*  </Badge>*/}
            {/*)}*/}
          </Button>
        ))}
      </Selector>

      <ColumnContainer>
        <PricingTableColumn
          plan={plans[activePlanIndex]}
          colorTheme={colorTheme}
          billingInterval={billingInterval}
          isMobileView
        />
      </ColumnContainer>
    </Container>
  );
}

const Selector = styled.div`
  margin-right: 12px;
  display: flex;
  flex-direction: column;
`;

const Button = styled(ButtonPrimitive)<{ _active: boolean }>`
  ${({ theme }) => theme.font.title4};

  position: relative;
  background: ${({ theme }) => theme.colors.white};
  min-width: 100px;
  height: 48px;
  padding: 0 12px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  white-space: nowrap;

  :not(:last-child) {
    margin-bottom: 12px;
  }

  ${({ theme: { colors }, _active }) => css`
    border: 1px solid ${_active ? colors.gray30 : colors.gray10};
    ${_active && `box-shadow: 0 2px 8px 0 ${colors.gray10};`}
  `}
`;

const Badge = styled(Chip)`
  position: absolute;
  top: -6px;
  right: -6px;
`;

const ColumnContainer = styled.div`
  display: contents;
`;

const Container = styled.div`
  margin: 8px auto;
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  justify-content: center;
`;
