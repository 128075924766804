import { UrlObject } from 'url';
import {
  AnchorHTMLAttributes,
  MouseEventHandler,
  PropsWithChildren,
  forwardRef
} from 'react';
import styled, { css } from 'styled-components';
import { ThemeColor } from '../theme';
import { NextLinkComposed } from '../next';

export type LinkProps = Omit<AnchorHTMLAttributes<HTMLAnchorElement>, 'href'> &
  PropsWithChildren<{
    color?: ThemeColor;
    onClick?: MouseEventHandler;
    underline?: boolean;
    target?: '_blank' | '_top' | '_self';
    href?: string | UrlObject;
    as?: string;
  }>;

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(function _Link(
  { color, children, href, underline, target, rel, as, ...forwardingProps },
  forwardingRef
) {
  const finalTarget = target || isExternal(href) ? '_blank' : undefined;
  const finalRel = rel || isExternal(href) ? 'noopener noreferrer' : undefined;

  return (
    <A
      {...forwardingProps}
      href={href}
      as={as || NextLinkComposed}
      target={finalTarget}
      rel={finalRel}
      $color={color}
      $underline={underline}
      ref={forwardingRef}
    >
      {children}
    </A>
  );
});

const isExternal = (href?: string | UrlObject) =>
  typeof href === 'string' && /^(mailto|tel|http)/i.test(href);

const A = styled.a<{
  $color?: ThemeColor;
  $underline?: boolean;
}>`
  cursor: pointer;

  &,
  &:active {
    color: ${({ theme, $color }) => ($color ? theme.colors[$color] : 'inherit')};
  }
  display: inline-block;
  ${({ $underline }) =>
    $underline
      ? css`
          text-decoration: underline;
          text-underline-offset: 2.5px;
        `
      : css`
          text-decoration: none;
          :hover {
            text-decoration: underline;
          }
        `}
`;
