import { ReactNode } from 'react';
import styled from 'styled-components';
import { H1, H3, MOBILE } from '@elfsight-universe/ui-common';
import { GetUpgradeContextResponse } from '@elfsight-universe/service-core-contracts/billing';
import { LayoutInner, LayoutSection } from '@components';
import { useUpgradeContext } from '../upgrade-context';
import { CTAClaim } from '../constants';
import { CTAAppTitle } from './cta-app-title';

export function CTASection() {
  const { claim, isLoading, data } = useUpgradeContext();

  if (!data) {
    return null;
  }

  const title = getTitle(data, claim);
  const isTitleBold = !data?.hasPaidSubscription && !claim;

  return (
    <LayoutSection _padding={[0, 32]}>
      {!isLoading && (
        <LayoutInner _textCenter>
          <Title mb={8} _bold={isTitleBold}>
            {title}
          </Title>
          <Caption color="gray70">
            14-day money back guarantee. Risk-free, you can cancel anytime.
          </Caption>
        </LayoutInner>
      )}
    </LayoutSection>
  );
}

const getTitle = (
  data: GetUpgradeContextResponse,
  claim: CTAClaim | undefined
): ReactNode => {
  if (claim) {
    return {
      [CTAClaim.REMOVE_BRANDING]: (
        <>
          Upgrade
          <CTAAppTitle /> to remove Elfsight branding
        </>
      ),
      [CTAClaim.UPGRADE_PLAN]: (
        <>
          Upgrade your
          <CTAAppTitle /> plan
        </>
      )
    }[claim];
  }

  if (data?.isLiteSubscription && !data?.isLiteSubscriptionConfirmed) {
    return <>Pick a plan to install the widget</>;
  }

  if (data?.isLiteSubscriptionConfirmed) {
    return <>Choose your plan</>;
  }

  return (
    <>
      Upgrade your
      <CTAAppTitle /> plan
    </>
  );
};

const Title = styled(H1)<{ _bold: boolean }>`
  padding: 0 20px;
  font-size: 28px;
  line-height: 36px;
  font-weight: ${({ _bold }) => (_bold ? 700 : 400)};

  ${MOBILE} {
    font-size: 24px;
    line-height: 30px;
    font-weight: 600;
  }
`;

const Caption = styled(H3)`
  font-weight: 400;
`;
