import { Exclude, Expose, Type } from 'class-transformer';
import { IsString } from 'class-validator';
import { WidgetError } from '../errors';

export const GET_WIDGETS_LIMIT_PATH = 'widget/get-widgets-limit';

export class GetWidgetsLimitRequest {
  @IsString()
  appAlias: string;
}

@Exclude()
export class GetWidgetsLimitResponse {
  @Expose()
  widgetsLimit: number;

  @Expose()
  widgetsCount: number;

  @Expose()
  @Type(() => GetWidgetsLimitResponseApp)
  app: {
    name: string;
    alias: string;
    icon: string;
    category: {
      color: string;
    };
  };

  @Expose()
  planName: string;

  @Expose()
  widgetsTotalCount: number;
}

@Exclude()
export class GetWidgetsLimitResponseApp {
  @Expose()
  name: string;

  @Expose()
  alias: string;

  @Expose()
  icon: string;

  @Expose()
  @Type(() => GetWidgetsLimitResponseAppCategory)
  category: {
    color: string;
  };
}

@Exclude()
export class GetWidgetsLimitResponseAppCategory {
  @Expose()
  color: string;
}

export type GetWidgetsLimitResponseError = WidgetError.APP_DOES_NOT_EXIST;
