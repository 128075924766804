import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  exactRoundingFloatValue,
  getColorWithAlpha
} from '@elfsight-universe/ui-common/src/utils';
import { Button } from '@elfsight-universe/ui-common/src/components/buttons';
import { ThemeColor } from '@elfsight-universe/ui-common';
import { useInstantExtensionCampaignContext } from '@modules/instant-extension-campaign/context/instant-extension-campaign-context';
import { BIRTHDAY_DEAL_ALIAS } from '@modules/deals/utils/deal-aliases';
import { useSubscribe } from '../utils';
import {
  PricingTableColorTheme,
  PricingTablePlan,
  PricingTableProps
} from './pricing-table-types';
import { FeatureTooltipInner } from './components/feature-tooltip';

export type PricingTableColumnProps = {
  plan: PricingTablePlan;
  isMobileView?: boolean;
  shouldDisplayYouSaveBanner?: boolean;
} & Pick<PricingTableProps, 'billingInterval' | 'colorTheme'>;

export function PricingTableColumn({
  plan,
  colorTheme = PricingTableColorTheme.WHITE,
  isMobileView = false,
  shouldDisplayYouSaveBanner = false
}: PricingTableColumnProps) {
  const [isSubscriptionLoading, handleSubscribe] = useSubscribe(plan);
  const [isButtonLoading, setButtonLoading] = useState(isSubscriptionLoading);
  const { alias } = useInstantExtensionCampaignContext();

  useEffect(() => {
    if (!isSubscriptionLoading) return setButtonLoading(false);
  }, [isSubscriptionLoading]);

  const buttonColor = ((): ThemeColor | undefined => {
    if (plan.isLitePlan && !isMobileView) {
      return undefined;
    }

    if (alias === BIRTHDAY_DEAL_ALIAS) {
      return {
        [PricingTableColorTheme.WHITE]: 'promo',
        [PricingTableColorTheme.BLACK]: 'brand'
      }[colorTheme] as ThemeColor;
    }

    const mapDefault: Record<PricingTableColorTheme, ThemeColor> = {
      [PricingTableColorTheme.WHITE]: 'accent',
      [PricingTableColorTheme.BLACK]: 'brand'
    };
    return mapDefault[colorTheme];
  })();

  const buttonText = ((): string => {
    if (!!plan.discountPercentage && !plan.isLitePlan) {
      return `Get ${exactRoundingFloatValue(
        plan.discountPercentage * 100
      )}% off`;
    }

    return 'Select';
  })();

  const displaySaleBadge = !!plan.discountPercentage && !plan.isLitePlan;
  const displayYouSaveBanner =
    shouldDisplayYouSaveBanner || !!plan.discountTotal;
  // const displayHighlightRibbon =
  //   !!plan.highlight && !displaySaleBadge && !isWelcomeDeal;

  const pricePerMonth = plan.pricePerMonthDiscounted || plan.pricePerMonth;
  const displayPerMonthDiscounted = !!plan.pricePerMonthDiscounted;

  return (
    <Column
      _highlight={!!plan.highlight && !isMobileView}
      _colorTheme={colorTheme}
    >
      {/*{displayHighlightRibbon && (*/}
      {/*  <ColumnHighlightRibbon>Popular</ColumnHighlightRibbon>*/}
      {/*)}*/}
      {displaySaleBadge && plan.discountPercentage && (
        <ColumnSaleBadge _backgroundColor="brand" _textColor="white">
          {exactRoundingFloatValue(plan.discountPercentage * 100)}% off
        </ColumnSaleBadge>
      )}

      <ColumnCell>
        <Name>{plan.name}</Name>
        {!plan.isEnterprisePlan && <Caption>{plan.caption}</Caption>}
      </ColumnCell>

      <ColumnCell _paddingTop={16}>
        <PriceContainer>
          <Price>
            <PriceCurrency>$</PriceCurrency>
            <PriceValue>{exactRoundingFloatValue(pricePerMonth)}</PriceValue>
            <PricePeriod>/ mo</PricePeriod>
          </Price>
          {displayPerMonthDiscounted ? (
            <PriceOld>
              <PriceOldCurrency>$</PriceOldCurrency>
              <PriceOldValue>
                {exactRoundingFloatValue(plan.pricePerMonth)}
                <PriceOldValueStroke _colorTheme={colorTheme} />
              </PriceOldValue>
            </PriceOld>
          ) : null}
        </PriceContainer>

        {plan.isEnterprisePlan && !plan.isPackPlan && (
          <EnterprisePrompt>
            ${exactRoundingFloatValue(plan.pricePerWidget)} per widget
          </EnterprisePrompt>
        )}

        {displayYouSaveBanner && (
          <>
            {plan.discountTotal ? (
              <YouSaveBanner _colorTheme={colorTheme}>
                You save <b>${exactRoundingFloatValue(plan.discountTotal)}</b> a
                year
              </YouSaveBanner>
            ) : (
              <Spacer _height={24} />
            )}
          </>
        )}

        {plan.current ? (
          <Current _colorTheme={colorTheme}>Current Plan</Current>
        ) : (
          <CenteredButton
            backgroundColor={buttonColor}
            variation={plan.isLitePlan ? 'secondary' : 'primary'}
            isLoading={isButtonLoading}
            fullWidth
            center
            onClick={() => {
              setButtonLoading(true);
              handleSubscribe();
            }}
          >
            {buttonText}
          </CenteredButton>
        )}
      </ColumnCell>

      <ColumnCell _paddingTop={32}>
        {plan.features.map(({ text, highlight, excluded }, featureIndex) => (
          <Feature
            key={`${plan.name}-${featureIndex}`}
            _excluded={excluded}
            _highlight={highlight}
            _colorTheme={colorTheme}
          >
            {text}
          </Feature>
        ))}
      </ColumnCell>
    </Column>
  );
}

const CenteredButton = styled(Button)`
  margin: 20px auto 0;
`;

const ColumnCell = styled.div<{
  _paddingBottom?: number;
  _paddingTop?: number;
}>`
  ${({ _paddingBottom }) =>
    _paddingBottom &&
    css`
      padding-bottom: ${_paddingBottom}px;
    `};

  ${({ _paddingTop }) =>
    _paddingTop &&
    css`
      padding-top: ${_paddingTop}px;
    `};
`;

const Name = styled.div`
  ${({ theme }) => theme.font.title2}
  margin-bottom: 2px;
`;

const Caption = styled.div`
  ${({ theme }) => theme.font.caption}
  white-space: nowrap;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  padding: 8px 0;
`;

const Price = styled.div`
  flex-shrink: 0;
  display: flex;
  gap: 2px;
`;

const PriceValue = styled.span`
  font-weight: 600;
  font-size: 52px;
  line-height: 40px;
`;

const PriceCurrency = styled.span`
  font-size: 14px;
  line-height: 14px;
  align-self: flex-start;
`;

const PricePeriod = styled.span`
  align-self: flex-end;
  font-size: 14px;
  line-height: 14px;
  vertical-align: baseline;
`;

const PriceOld = styled.div`
  align-self: flex-start;
  display: flex;
`;

const PriceOldCurrency = styled.span`
  font-size: 14px;
  line-height: 14px;
  align-self: flex-start;
  margin-right: 2px;
`;

const PriceOldValue = styled.span`
  font-size: 24px;
  font-weight: 600;
  line-height: 20px;
  position: relative;
`;

const PriceOldValueStroke = styled.span<{
  _colorTheme: PricingTableColorTheme;
}>`
  position: absolute;
  left: -2px;
  right: -2px;
  height: 1px;
  top: 50%;
  rotate: -6deg;
  background: currentColor;

  :before {
    position: absolute;
    width: 100%;
    height: 1px;
    top: 1px;
    content: '';
    background: ${({ theme: { colors }, _colorTheme }) =>
      ({
        black: '#272727',
        white: colors.white
      })[_colorTheme]};
  }
`;

const EnterprisePrompt = styled.div`
  display: block;
  padding: 4px 0 12px 0;
  ${({ theme }) => theme.font.caption}
`;

const YouSaveBanner = styled.div<{
  _colorTheme: PricingTableColorTheme;
}>`
  ${({ theme }) => theme.font.caption}
  padding: 4px 0;
  text-align: center;
  background-color: ${({ theme: { colors }, _colorTheme }) =>
    ({
      black: getColorWithAlpha(colors.white, 0.1),
      white: 'rgba(106, 138, 182, 0.09)'
    })[_colorTheme]};
  mask-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTg0IiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMTg0IDI0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgoJPHBhdGggZD0iTTE4Mi44ODcgNS41MDQ5QzE4NC41MjYgMTMuOTAzMiAxNzguMzIxIDIxLjc4MzEgMTY5Ljc3IDIyLjA5NTdDMTUxLjAxOSAyMi43ODE0IDExOS4yNDMgMjMuNzcwNSA5MS45NzA5IDIzLjc3MDVDNTAuMjMxNiAyMy43NzA1IDAgMjAuNzYwOSAwIDIwLjc2MDlMNi42NjA2MyAyLjg3NDIxQzYuNjYwNjMgMi44NzQyMSA1Mi43MTk3IDQuMzExMzIgOTUuMzY5NyA0LjMxMTMyQzEzOC4wMiA0LjMxMTMyIDE4MS44MTMgMCAxODEuODEzIDBMMTgyLjg4NyA1LjUwNDlaIi8+Cjwvc3ZnPgo=');
  mask-position: center;
  mask-repeat: no-repeat;
  margin-top: 8px;
`;

const Spacer = styled.div<{ _height: number }>`
  height: ${({ _height }) => _height}px;
  margin-top: 8px;
`;

const Current = styled.div<{
  _colorTheme: PricingTableColorTheme;
}>`
  ${({ theme }) => theme.font.title4Semibold};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 34px;
  margin-top: 20px;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  border-color: ${({ theme: { colors }, _colorTheme }) =>
    ({
      black: getColorWithAlpha(colors.white, 0.1),
      white: colors.gray5
    })[_colorTheme]};

  color: ${({ theme: { colors }, _colorTheme }) =>
    ({
      black: getColorWithAlpha(colors.white, 0.5),
      white: colors.gray50
    })[_colorTheme]};
`;

const Feature = styled.div<{
  _highlight?: boolean;
  _excluded?: boolean;
  _colorTheme: PricingTableColorTheme;
}>`
  ${({ theme }) => theme.font.text};

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }

  ${({ _highlight }) =>
    _highlight &&
    css`
      font-weight: 700;
    `};

  ${({ _excluded, _colorTheme, theme }) =>
    _excluded &&
    css`
      color: ${
        {
          black: getColorWithAlpha(theme.colors.white, 0.5),
          white: theme.colors.gray50
        }[_colorTheme]
      };
      text-decoration: line-through;

      > ${FeatureTooltipInner} {
        text-decoration: line-through;
      }
    `};
`;

// const ColumnHighlightRibbon = styled.div`
//   position: absolute;
//   top: 16px;
//   right: -36px;
//   transform: rotate(45deg);
//   background-color: #ffec00;
//   padding: 4px 40px;
//   color: #17191a;
// `;

const ColumnSaleBadge = styled.div<{
  _backgroundColor: ThemeColor;
  _textColor: ThemeColor;
}>`
  position: absolute;
  top: 16px;
  right: 0;
  padding: 0 6px;
  background-color: ${({ theme, _backgroundColor }) =>
    theme.colors[_backgroundColor]};
  color: ${({ theme, _textColor }) => theme.colors[_textColor]};
  ${({ theme }) => theme.font.captionBold}
  line-height: 20px;

  &:after {
    content: '';
    position: absolute;
    left: -4px;
    bottom: 0;
    width: 0;
    height: 0;
    border-left: 4px solid transparent;
    ${({ theme, _backgroundColor }) => css`
      border-top: 10px solid ${theme.colors[_backgroundColor]};
      border-bottom: 10px solid ${theme.colors[_backgroundColor]};
    `}
  }
`;

const Column = styled.div<{
  _highlight?: boolean;
  _colorTheme: PricingTableColorTheme;
}>`
  overflow: hidden;
  max-width: 250px;
  box-sizing: border-box;
  text-align: left;
  flex: 1 1 250px;
  border-color: ${({ theme }) => theme.colors.gray5};
  border-style: solid;
  position: relative;
  padding: 28px 32px 36px;

  border-top-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  border-bottom-width: 0;
  background: ${({ theme: { colors }, _colorTheme }) =>
    ({
      black: '#272727',
      white: colors.white
    })[_colorTheme]};
  color: ${({ theme: { colors }, _colorTheme }) =>
    ({
      black: colors.white,
      white: colors.black
    })[_colorTheme]};

  ${PricePeriod},
  ${PriceCurrency},
  ${PriceOld} {
    color: ${({ _colorTheme }) =>
      ({
        black: '#939393',
        white: '#888888'
      })[_colorTheme]};
  }

  ${Caption} {
    color: ${({ theme: { colors }, _colorTheme }) =>
      ({
        black: getColorWithAlpha(colors.white, 0.7),
        white: colors.gray70
      })[_colorTheme]};
  }

  :not(:last-child) {
    border-right-width: 1px;
  }
  :only-child {
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(17, 17, 17, 0.1);
  }
  :first-child:not(:only-child) {
    border-radius: 8px 0 0 8px;
  }
  :last-child:not(:only-child) {
    border-radius: 0 8px 8px 0;
  }

  ${({ _highlight }) =>
    _highlight &&
    css`
      ${ColumnSaleBadge} {
        margin-top: 8px;
      }

      :not(:only-child) {
        border-radius: 8px;
        margin: -8px 0;
        border-right-width: 0;
        padding: 36px 32px 44px;
        box-shadow: 0 8px 20px 0 rgb(0 0 1 / 16%);
        z-index: 1;
      }
    `}
`;
