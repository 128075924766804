import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { H2, Typography } from '@elfsight-universe/ui-common';
import { OnboardingItemsWithDone } from '@modules/_header/header-onboarding-container';
import { OnboardingCongratulations } from './onboarding-congratulations';

export type OnboardingBlockProps = {
  items: OnboardingItemsWithDone[];
  note: string;
  done: boolean;
};

export function OnboardingBlock({ items, note, done }: OnboardingBlockProps) {
  const [openItemId, setOpenItemId] = useState<string | undefined>(undefined);

  useEffect(() => {
    const firstUndoneItemId = items.filter(({ done }) => !done)[0]?.action;
    setOpenItemId(firstUndoneItemId);
  }, [items]);

  if (done) {
    return (
      <Container>
        <OnboardingCongratulations />
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <H2>Getting Started</H2>
        <Typography variant="caption" color="gray70">
          {note}
        </Typography>
      </Header>
      {items.map(({ action, done, component: Component }) => {
        return (
          <Component
            key={action}
            type={action}
            done={done}
            open={openItemId === action}
            onClick={() => {
              setOpenItemId(openItemId === action ? undefined : action);
            }}
          />
        );
      })}
    </Container>
  );
}

const Container = styled.div``;

const Header = styled.div`
  margin-bottom: 20px;
`;
