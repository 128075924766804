import { useEffect, useRef, useState } from 'react';
import { Socket } from 'socket.io-client';
import { onlineManager } from '@tanstack/react-query';
import { connectToWebsocketApi } from '../../../api/misc/connect-to-websocket-api';

export function useOnlineSocketConnection() {
  const socket = useRef<Socket | undefined>(undefined);
  const [isConnected, setIsConnected] = useState<boolean>(true);

  const start = async () => {
    if (socket.current) {
      return;
    }

    socket.current = await connectToWebsocketApi({
      path: '/api/socketio',
      reconnectionDelayMax: 3000
    });

    socket.current.on('connect', () => {
      setIsConnected(true);
      setOnline(true);
    });

    socket.current.on('disconnect', (reason) => {
      setIsConnected(false);
      setOnline(false);

      if (reason === 'io server disconnect') {
        reconnect();
      }
    });

    socket.current.on('reconnect', () => {
      setIsConnected(true);
      setOnline(true);
    });

    socket.current.on('reconnect_error', () => {
      setIsConnected(false);
      setOnline(false);
    });
  };

  const setOnline = (value: boolean | undefined) => {
    if (value) {
      onlineManager.setOnline(true);
      onlineManager.setOnline(undefined);
    } else {
      onlineManager.setOnline(value);
    }
  };

  const close = () => {
    if (socket.current) {
      socket.current.close();
      socket.current = undefined;
    }
  };

  const reconnect = () => {
    start();
  };

  useEffect(() => {
    start();

    return () => {
      close();
    };
  }, []);

  return {
    isConnected,
    start,
    close,
    reconnect,
    socket: socket.current
  };
}
