import { AccountDeal } from '@elfsight-universe/service-core-contracts/deals';
import { Link, P } from '@elfsight-universe/ui-common';
import { helpscoutArticles } from '@constants';
import { beacon } from '@modules/beacon';
import {
  Coupon,
  DefaultContainer,
  HowToApply,
  HowToApplyListItem
} from '@modules/deals/ui';

const howToUseCouponLink = (
  <Link
    color="accent"
    onClick={() => beacon.article(helpscoutArticles.coupon.id)}
  >
    How to use coupon
  </Link>
);

type SecondAppDetailTemplateProps = AccountDeal;

export function SecondAppDetailTemplate({
  deal,
  coupon
}: SecondAppDetailTemplateProps) {
  const { title, caption, textImage, alias } = deal;

  return (
    <DefaultContainer title={title} textImage={textImage} alias={alias}>
      <P big>{caption}</P>

      <HowToApply>
        <HowToApplyListItem>
          <span>1. Copy coupon code below:</span>
          <div>
            <Coupon coupon={coupon?.code as string} />
          </div>
        </HowToApplyListItem>
        <HowToApplyListItem>
          <span>
            2. Select any Single App paid plan and apply the coupon during
            checkout.
          </span>
        </HowToApplyListItem>
        <HowToApplyListItem>
          <span>{howToUseCouponLink}</span>
        </HowToApplyListItem>
      </HowToApply>
    </DefaultContainer>
  );
}
