import { ReactNode } from 'react';
import { StarScale } from '@elfsight/icons';
import { Icon, Link } from '@elfsight-universe/ui-common';
import { useCountPublicAppsQuery } from '@api';

type TestimonialsItemType = {
  id: string;
  caption: ReactNode;
  description: ReactNode;
};

export const useTestimonialsItems = (): TestimonialsItemType[] | null => {
  const {
    data: publicAppsCountData = { count: 0 },
    isLoading: isLoadingPublicAppsCount
  } = useCountPublicAppsQuery();

  if (isLoadingPublicAppsCount) {
    return null;
  }

  return [
    {
      id: '1',
      caption: '2M+',
      description: 'Users globally trust Elfsight to upgrade their websites'
    },
    {
      id: '2',
      caption: '#1',
      description: 'Top service in the world when it comes to website widgets'
    },
    {
      id: '3',
      caption: (
        <>
          <Icon size={28} component={StarScale} fill="warning" /> 4.9
        </>
      ),
      description: (
        <>
          Rating from{' '}
          <Link
            color="accent"
            href="https://www.g2.com/products/elfsight/reviews"
          >
            thousands of reviews
          </Link>{' '}
          gathered over our 12-year history
        </>
      )
    },
    {
      id: '4',
      caption: publicAppsCountData.count || '90+',
      description: (
        <>
          Widgets are available in our consistently{' '}
          <Link color="accent" href="https://elfsight.com/widgets">
            growing collection
          </Link>
        </>
      )
    }
  ];
};
