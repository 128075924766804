import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState
} from 'react';
import { GetFeaturedOfferResponse } from '@elfsight-universe/service-core-contracts/deals';
import {
  useWebStorage,
  UseWebStorageOnReady
} from '@elfsight-universe/react-web-storage';
import { useFeaturedOfferQuery } from '@api';
import { usePageContext, useIsMobile } from '@modules/_app';
import { OfferSlideInLayout } from './offer-slide-in/offer-slide-in-layout';

export type IOfferContext = {
  offer?: GetFeaturedOfferResponse;
  isBarClosed: boolean;
  closeBar: () => void;
  slideInOpen: boolean;
  toggleSlideInOpen: (state?: boolean, delay?: number) => void;
};

export const OfferContext = createContext<IOfferContext | null>(null);

export function OfferProvider({ children }: PropsWithChildren) {
  const [isBarClosed, setBarClosed] = useState(false);
  const closeBar = () => setBarClosed(true);
  const { offerHidden, offerSlideInOpenByDefault } = usePageContext();
  const isMobile = useIsMobile();
  const [slideInOpen, setSlideInOpen] = useState(false);

  const toggleSlideInOpen = (state?: boolean, delay = 0) => {
    const nextOpen = state === undefined ? !slideInOpen : state;

    setTimeout(() => {
      setSlideInOpen(nextOpen);
    }, delay);

    if (!nextOpen) {
      storeSlideInClosed(true);
    }
  };

  const { data: offer } = useFeaturedOfferQuery({}, () =>
    toggleSlideInOpen(true)
  );

  const onReadyCallback: UseWebStorageOnReady<boolean> = useCallback(
    (wasClosed) => {
      setSlideInOpen(offerSlideInOpenByDefault && !wasClosed && !isMobile);
    },
    [offerSlideInOpenByDefault]
  );

  // @TODO implement useDisposedInSessionStorage instead
  const { storeValue: storeSlideInClosed } = useWebStorage<boolean>({
    key: `offer-slide-in-${offer?.type}-closed`,
    onReady: onReadyCallback
  });

  return (
    <OfferContext.Provider
      value={{
        offer,
        isBarClosed,
        closeBar,
        slideInOpen,
        toggleSlideInOpen
      }}
    >
      {children}

      {!offerHidden && <OfferSlideInLayout type={offer?.type} />}
    </OfferContext.Provider>
  );
}

export function useOffer() {
  const context = useContext(OfferContext);

  if (context === null) {
    throw new Error('`useOffer` must be nested inside an `OfferProvider`.');
  }

  return context;
}
