import { Transform } from 'class-transformer';
import { IsString } from 'class-validator';
import { JsonObject, JsonValue } from '../../json.type';
import { BillingError } from '../../errors';
import { PaddleSubscriptionStatus } from '../enums';
import type { PaddlePassthrough } from '../interfaces/paddle-passthrough';
import { PaddleAlertName } from '../enums/paddle-alert-name';
import { toJSON } from '../../utils/to-json';

export class SubscriptionUpdatedRequest implements JsonObject {
  [K: string]: JsonValue;

  @IsString()
  alert_id: string;

  @IsString()
  alert_name: PaddleAlertName;

  @IsString()
  cancel_url: string;

  @IsString()
  checkout_id: string;

  @IsString()
  currency: string;

  @IsString()
  email: string;

  @IsString()
  event_time: string;

  @IsString()
  linked_subscriptions: string;

  @IsString()
  marketing_consent: string;

  @IsString()
  new_price: string;

  @IsString()
  new_quantity: string;

  @IsString()
  new_unit_price: string;

  @IsString()
  next_bill_date: string;

  @IsString()
  old_next_bill_date: string;

  @IsString()
  old_price: string;

  @IsString()
  old_quantity: string;

  @IsString()
  old_status: PaddleSubscriptionStatus;

  @IsString()
  old_subscription_plan_id: string;

  @IsString()
  old_unit_price: string;

  @Transform(({ value }) => toJSON(value))
  passthrough: PaddlePassthrough;

  @IsString()
  status: PaddleSubscriptionStatus;

  @IsString()
  subscription_id: string;

  @IsString()
  subscription_plan_id: string;

  @IsString()
  update_url: string;

  @IsString()
  user_id: string;

  @IsString()
  p_signature: string;
}

export type SubscriptionUpdatedResponseError =
  | BillingError.SUBSCRIPTION_DOES_NOT_EXIST
  | BillingError.PLAN_DOES_NOT_EXIST;
