import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_CONNECTED_WIDGET_PATH,
  GetConnectedWidgetResponse
} from '@elfsight-universe/service-core-contracts/wix-integration';
import { client } from '@api';

export function useConnectedWidgetQuery(
  wixWidgetId: string,
  options: UseQueryOptions<GetConnectedWidgetResponse> = {}
) {
  return useQuery<GetConnectedWidgetResponse>(
    [GET_CONNECTED_WIDGET_PATH, wixWidgetId],
    () =>
      client
        .get(GET_CONNECTED_WIDGET_PATH, {
          searchParams: { wixWidgetId }
        })
        .json<GetConnectedWidgetResponse>(),
    {
      ...options
    }
  );
}
