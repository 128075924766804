import styled, { css } from 'styled-components';
import { useState } from 'react';
import { MenuButton, Divider, User } from '@elfsight-universe/ui-common';
import {
  useSignOutMutation,
  useCollaboratorsFeatureSummaryQuery,
  useUserAccountsQuery
} from '@api';
import { useImpersonateSession } from '@modules/impersonate-session/impersonate-session-provider';
import { useUser } from '../_app';
import { SwitchAccountPage } from './switch-account';

export type HeaderUserPopoverProps = {
  onRequestClose: () => void;
};

export function HeaderUserPopover({ onRequestClose }: HeaderUserPopoverProps) {
  const { user } = useUser();
  const { data: accountsResponse } = useUserAccountsQuery();
  const { finishImpersonateSession } = useImpersonateSession();
  const { mutate: signOut } = useSignOutMutation();
  const { data: collaboratorsFeatureSummaryData } =
    useCollaboratorsFeatureSummaryQuery();
  const [switchAccountPageOpened, toggleSwitchAccountPageOpened] =
    useState(false);

  const closePopover = () => {
    onRequestClose();
    return true;
  };

  const showSwitchAccountMenuButton =
    accountsResponse && accountsResponse.accounts.length > 1;

  return (
    <Container>
      <Content active={!switchAccountPageOpened}>
        {user && (
          <>
            <StyledUser {...user} />
            <Divider _marginY={8} />
          </>
        )}

        <MenuButton href="/account/profile" onClick={closePopover}>
          Account Settings
        </MenuButton>
        <MenuButton href="/billing" onClick={closePopover}>
          Billing
        </MenuButton>

        {collaboratorsFeatureSummaryData &&
          collaboratorsFeatureSummaryData.available && (
            <MenuButton href="/members" onClick={closePopover}>
              Members
            </MenuButton>
          )}

        <Divider _marginY={8} />

        {showSwitchAccountMenuButton && (
          <>
            <MenuButton
              onClick={() => toggleSwitchAccountPageOpened(true)}
              withArrowIcon
              iconColor="gray50"
              iconHoverColor="black"
              large
            >
              Switch Workspace
            </MenuButton>

            <Divider _marginY={8} />
          </>
        )}

        {!user?.isAffiliate && (
          <>
            <MenuButton href="/refer-a-friend" onClick={closePopover}>
              Refer a Friend
              <ReferBadge>GET $15</ReferBadge>
            </MenuButton>
          </>
        )}

        {!user?.isImpersonateSession ? (
          <MenuButton onClick={() => signOut()}>Log out</MenuButton>
        ) : (
          <MenuButton onClick={finishImpersonateSession}>
            Finish Impersonate Session
          </MenuButton>
        )}
      </Content>

      <SwitchAccountPageContainer active={switchAccountPageOpened}>
        <SwitchAccountPage
          onBack={() => toggleSwitchAccountPageOpened(false)}
          onClose={() => onRequestClose()}
        />
      </SwitchAccountPageContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 260px;
`;

const Content = styled.div<{ active: boolean }>`
  padding: 16px 20px;
  box-sizing: border-box;
  width: 260px;
  transition: transform 0.2s;

  ${({ active }) =>
    !active &&
    css`
      transform: translateX(-260px);
    `};
`;

const SwitchAccountPageContainer = styled.div<{ active: boolean }>`
  padding: 16px 8px;
  width: 260px;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  transform: translateX(260px);
  transition: transform 0.2s;

  ${({ active }) =>
    active &&
    css`
      transform: translateX(0);
    `}
`;

const ReferBadge = styled.span`
  ${({ theme }) => theme.font.captionSmallUppercase};
  color: ${({ theme }) => theme.colors.brand};
  position: relative;
  bottom: 1px;
  margin-left: 8px;
`;

const StyledUser = styled(User)`
  margin-bottom: 16px;
`;
