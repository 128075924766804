import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { WarningFilled } from '@elfsight/icons';
import {
  AutosizeTextarea,
  Button,
  Callout,
  H4,
  Icon,
  MOBILE,
  Typography
} from '@elfsight-universe/ui-common';
import { CreateInstallationRequestAppealRequest } from '@elfsight-universe/service-core-contracts/appeal';
import BulbIcon from '@icons/bulb-outline-20.svg';
import { beacon } from '@modules/beacon';
import { requestInstallationCollaboratorTutorialsList } from '../constants/request-installation-collaborator-tutorials-list';

export function CollaboratorBlock({ ...forwardingProps }) {
  const {
    register,
    formState: { errors, touchedFields },
    getValues,
    clearErrors
  } = useFormContext<CreateInstallationRequestAppealRequest>();

  const onShareAccessGuideClick = () => {
    const platformName = getValues('platform');
    const platformTutorial = requestInstallationCollaboratorTutorialsList.find(
      ({ platform }) => platform === platformName
    );
    if (platformTutorial) {
      beacon.article(platformTutorial.articleID);
    }
  };

  return (
    <Container {...forwardingProps}>
      <Header>
        <Caption>
          <StyledH4 mb={4}>
            Add our developer as a Collaborator on your site. *
          </StyledH4>

          <Typography variant="text" mb={8}>
            Share your access and type in &quot;Done&quot;. Or provide the
            necessary access details (username and password, or any specific
            instructions for access).
          </Typography>
        </Caption>

        <Button
          negativeOffset={-12}
          padding={12}
          type="button"
          variation="text"
          small
          disableShrink
          textColor="accent"
          leftSlot={<Icon component={BulbIcon} size={16} fill="currentColor" />}
          onClick={onShareAccessGuideClick}
        >
          Find out how to share access
        </Button>
      </Header>

      <AutosizeTextarea
        {...register('accessDetails', {
          onChange: () => {
            clearErrors('accessDetails');
          }
        })}
        error={errors?.accessDetails?.message}
      />

      <StyledCallout
        icon={<Icon size={16} component={WarningFilled} fill="warning" />}
        backgroundColor="warning"
        direction="row"
      >
        Please make sure you&apos;ve shared access to your website&apos;s
        backend as we&apos;re unable to install the widget without it.
      </StyledCallout>
    </Container>
  );
}

const Container = styled.div<{ _isError?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  ${MOBILE} {
    order: 1;
    margin-top: 24px;
    margin-bottom: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
  margin-bottom: 8px;

  ${MOBILE} {
    flex-direction: column;
    gap: 4px;
    margin-bottom: 4px;
  }
`;

const StyledH4 = styled(H4)`
  ${({ theme }) => theme.font.title4Semibold};
`;

const Caption = styled.div``;

const StyledCallout = styled(Callout)`
  margin-top: 12px;
`;
