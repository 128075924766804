import { formatSeparatorNumber } from '@elfsight-universe/ui-common';
import {
  PlanGrade,
  PlanType
} from '@elfsight-universe/service-core-contracts/billing/enums';
import {
  GetPackPlansResponsePlan,
  GetSingleAppPlansResponsePlan
} from '@elfsight-universe/service-core-contracts/billing';
import {
  createPricingTableFeatures,
  PricingTableColorTheme,
  PricingTablePlan
} from '@modules/upgrade/pricing-table';

interface AdditionalProperties {
  app?: {
    alias: string;
    name: string;
  };
  effectivePlanPid?: string;
  pricingTableColorTheme?: PricingTableColorTheme;
  countPublicApps?: number;
}

export function formatPlans(
  plans: (GetSingleAppPlansResponsePlan | GetPackPlansResponsePlan)[],
  {
    app,
    effectivePlanPid,
    pricingTableColorTheme,
    countPublicApps
  }: AdditionalProperties = {}
): PricingTablePlan[] {
  return plans.map((plan) => {
    return {
      ...plan,
      pid: plan.pid,
      features: createPricingTableFeatures(
        {
          appsCount:
            plan instanceof GetSingleAppPlansResponsePlan ? 1 : plan.appsCount,
          widgetsLimit: plan.policy.widgetsLimit,
          viewsLimit:
            plan.policy.viewsLimit !== null
              ? formatSeparatorNumber(plan.policy.viewsLimit)
              : null,
          isLitePlan: plan.type === PlanType.LITE,
          isBasicPlan: plan.properties.grade === PlanGrade.BASIC,
          isProPlan: plan.properties.grade === PlanGrade.PRO,
          isPremiumPlan: plan.properties.grade === PlanGrade.PREMIUM,
          isEnterprisePlan: plan.isEnterprisePlan,
          isPackPlan: plan.type === PlanType.ALL_APPS_PACK,
          appAlias: app?.alias,
          appName: app?.name,
          projectsLimit: plan.policy.projectsLimit,
          collaboratorsLimit: plan.policy.collaboratorsLimit
        },
        {
          pricingTableColorTheme,
          countPublicApps
        }
      ),
      ...plan.properties,
      ...plan.policy,
      pricePerMonth: plan.pricePerMonth,
      pricePerWidget: plan.pricePerWidget,
      pricePerMonthDiscounted: plan.pricePerMonthDiscounted,
      discountTotal: plan.discountTotal,
      discountPercentage: plan.discountPercentage,
      isLitePlan: plan.type === PlanType.LITE,
      isPackPlan: plan.type === PlanType.ALL_APPS_PACK,
      paddleId: plan.properties.paddleId || undefined,
      current: plan.pid === effectivePlanPid
    };
  });
}
