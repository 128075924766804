import {
  ChangeEvent,
  ChangeEventHandler,
  FocusEventHandler,
  KeyboardEvent,
  MouseEvent,
  ReactNode,
  SelectHTMLAttributes,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  autoUpdate,
  flip,
  FloatingFocusManager,
  FloatingPortal,
  offset,
  size,
  useDismiss,
  useFloating,
  useInteractions,
  useListNavigation,
  useRole,
  useTransitionStyles,
  Placement,
  Strategy
} from '@floating-ui/react';
import styled from 'styled-components';
import { useFocused } from '../use-focused';
import { Control } from '../control';
import { customScrollbarMixin } from '../../custom-scrollbar-mixin';
import { ControlProps, ControlSize } from '../control-types';
import { createDropdownSlideInAnimation } from '../../popovers/create-dropdown-slide-in-animation';
import { ControlInput } from '../control-layout';
import { SelectOption } from './select-option';
import { SelectIndicator } from './select-indicator';
import { SelectChangeEvent } from './select-popover-legacy';
import { GroupedSelectOptionsList } from './components';
import { getFilteredSelectOptions, getSelectOptionsSuggestions } from './utils';

const OFFSET = 4;

export type ComboboxProps = Omit<
  SelectHTMLAttributes<HTMLInputElement>,
  'onChange' | 'value'
> &
  ControlProps<{
    placeholder?: ReactNode;
    options: SelectOption[];
    onFocus?: FocusEventHandler;
    onBlur?: FocusEventHandler;
    maxHeight?: number;
    onChange: (event: SelectChangeEvent) => void;
    onInputChange?: ChangeEventHandler<HTMLInputElement>;
    initialValue?: string;
    preventClearingValue?: boolean;
    placement?: Placement;
    floatingStrategy?: Strategy;
  }>;

export function Combobox({
  options: initialOptions,
  placeholder,
  large,
  fullWidth,
  maxWidth,
  label,
  error,
  captionTop,
  captionBottom,
  onFocus,
  onBlur,
  maxHeight,
  onChange,
  onInputChange,
  initialValue,
  preventClearingValue,
  placement = 'bottom',
  floatingStrategy,
  className,
  ...forwardingProps
}: ComboboxProps) {
  const [focused, focusHandlers] = useFocused(onFocus, onBlur);
  const [items, setItems] = useState(initialOptions);
  const [isOpen, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [selectedItem, setSelectedItem] = useState<SelectOption | null>(null);
  const [showNotFound, setShowNotFound] = useState(false);
  const [showFullList, setShowFullList] = useState(false);

  const listRef = useRef<Array<HTMLElement | null>>([]);

  useEffect(() => {
    const initialSelectedItem = initialOptions.find(
      ({ value }) => value === initialValue
    );
    if (initialSelectedItem) {
      setSelectedItem(initialSelectedItem);
      setInputValue(initialSelectedItem.label);
    }
  }, [initialValue]);

  const handleOpenChange = (open: boolean) => {
    setOpen(open);
    if (open) {
      return;
    }

    if (!selectedItem) {
      resetState();
      return;
    }

    setInputValue(selectedItem.label);
    setShowFullList(true);
    setShowNotFound(false);
    setItems(initialOptions);
  };

  const { refs, floatingStyles, context } = useFloating<HTMLInputElement>({
    open: isOpen,
    onOpenChange: handleOpenChange,
    whileElementsMounted: autoUpdate,
    placement,
    strategy: floatingStrategy,
    middleware: [
      offset(OFFSET),
      flip({ padding: 10, fallbackStrategy: 'initialPlacement' }),
      size({
        apply: ({ rects, availableHeight, elements }) => {
          const finalMaxHeight =
            maxHeight && maxHeight < availableHeight
              ? maxHeight
              : availableHeight;

          Object.assign(elements.floating.style, {
            width: `${rects.reference.width}px`,
            maxHeight: `${finalMaxHeight}px`
          });
        },
        padding: 10
      })
    ]
  });

  const role = useRole(context, { role: 'listbox' });
  const dismiss = useDismiss(context);
  const listNav = useListNavigation(context, {
    listRef,
    activeIndex,
    selectedIndex,
    onNavigate: setActiveIndex,
    virtual: true,
    loop: true
  });

  const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions(
    [role, dismiss, listNav]
  );

  const { isMounted, styles: transitionStyles } = useTransitionStyles(
    context,
    createDropdownSlideInAnimation()
  );

  const resetState = () => {
    setInputValue('');
    setShowFullList(true);
    setShowNotFound(false);
    setSelectedItem(null);
    setSelectedIndex(null);
    setActiveIndex(null);
    setItems(initialOptions);

    if (!preventClearingValue) {
      onChange({
        target: { value: '' }
      } as ChangeEvent<HTMLSelectElement>);
    }
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onInputChange && onInputChange(event);
    if (!event.target.value && !preventClearingValue) {
      resetState();
      return;
    }

    setOpen(true);
    const value = event.target.value;
    const filteredItems = getFilteredSelectOptions({
      items: initialOptions,
      filter: value
    });
    const suggestions = getSelectOptionsSuggestions(initialOptions);
    const finalItems = filteredItems.length > 0 ? filteredItems : suggestions;
    setItems(finalItems);
    setInputValue(value);
    setShowNotFound(filteredItems.length === 0);
    setShowFullList(false);
  };

  const handleInputClick = (event: MouseEvent<HTMLInputElement>) => {
    if (preventClearingValue && selectedItem) {
      setInputValue(selectedItem.label);
    }
    if (!inputValue) {
      setOpen((prevState) => !prevState);
      return;
    }
    setOpen(true);
  };

  const handleInputKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && activeIndex != null && items[activeIndex]) {
      handleItemSelected(items[activeIndex], activeIndex);
    }
  };

  const handleItemSelected = (item: SelectOption, index: number) => {
    setInputValue(item.label);
    setSelectedIndex(index);
    setSelectedItem(item);
    setShowFullList(true);
    setShowNotFound(false);
    setOpen(false);
    setItems(initialOptions);
    onChange({
      target: { value: item.value }
    } as ChangeEvent<HTMLSelectElement>);
    refs.domReference.current?.focus();
  };

  const controlSize: ControlSize = large ? 'large' : 'medium';
  const fontSize = { large: 16, medium: 14 }[controlSize];
  const height = { large: 48, medium: 36 }[controlSize];

  return (
    <>
      <Control
        fontSize={fontSize}
        height={height}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        focused={focused}
        label={label}
        error={error}
        captionTop={captionTop}
        captionBottom={captionBottom}
        className={className}
      >
        <ControlInput
          {...getReferenceProps({
            ref: refs.setReference,
            onChange: handleInputChange,
            onClick: handleInputClick,
            onKeyDown: handleInputKeyDown,
            value: inputValue,
            placeholder: placeholder ? `${placeholder}…` : undefined,
            'aria-autocomplete': 'list',
            ...focusHandlers,
            ...forwardingProps
          })}
        />

        <StyledSelectIndicator _opened={isMounted} />
      </Control>
      {isMounted && (
        <FloatingPortal>
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
            visuallyHiddenDismiss
          >
            <Content
              {...getFloatingProps({
                ref: refs.setFloating,
                style: {
                  ...floatingStyles,
                  ...transitionStyles
                }
              })}
            >
              <OptionsList>
                {isOpen && showNotFound && (
                  <GroupSection>
                    <Empty aria-disabled="true">No results found</Empty>
                    {items.length > 0 && <Separator />}
                  </GroupSection>
                )}

                <GroupedSelectOptionsList
                  items={items}
                  withGroups={showFullList || !inputValue.trim()}
                  selectedItem={selectedItem}
                  activeIndex={activeIndex}
                  onSelectItem={handleItemSelected}
                  floatingUIItemProps={getItemProps}
                  itemsListRef={listRef}
                />
              </OptionsList>
            </Content>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
}

const Content = styled.div`
  margin: 0;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 6px 18px 0 rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 8px 0;
  overflow-y: auto;
  outline: none;
  z-index: ${({ theme }) => theme.zIndex.contextMenuDropdown};

  ${customScrollbarMixin};
`;

const StyledSelectIndicator = styled(SelectIndicator)`
  position: absolute;
  right: 0;
  pointer-events: none;
`;

const GroupSection = styled.li`
  list-style-type: none;
`;

const Separator = styled.div.attrs({
  role: 'separator',
  'aria-disabled': true
})`
  height: 1px;
  margin: 8px 16px;
  background-color: ${({ theme }) => theme.colors.gray10};
`;

const OptionsList = styled.ul`
  padding: 0;
  margin: 0;
`;

const Empty = styled.div`
  color: ${({ theme }) => theme.colors.gray50};
  padding: 0 16px;
  height: 32px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;
