import styled from 'styled-components';
import { AnnouncementsCardSkeleton } from '@modules/announcements/announcements-card-skeleton';
import { AnnouncementsNewDivider } from '@modules/announcements/components/announcements-new-divider';
import { AnnouncementCard } from './announcements-card';
import { useAnnouncementsContext } from './announcements-provider';

export function AnnouncementsList() {
  const { data, numberOfUnreadAnnouncements, isLoading } =
    useAnnouncementsContext();

  return (
    <Container>
      {data?.pages.map((announcementsResponse) => {
        return announcementsResponse.payload.map((announcement, index) => {
          const showDivider =
            numberOfUnreadAnnouncements > 0 &&
            index === numberOfUnreadAnnouncements - 1 &&
            index !== announcementsResponse.payload.length - 1;

          return (
            <CardWrapper key={announcement.pid}>
              <AnnouncementCard announcement={announcement} />
              {showDivider && <StyledAnnouncementsNewDivider />}
            </CardWrapper>
          );
        });
      })}
      {isLoading && <AnnouncementsCardSkeleton />}
    </Container>
  );
}

const Container = styled.div``;

const CardWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 8px;
  }`;

const StyledAnnouncementsNewDivider = styled(AnnouncementsNewDivider)`
  margin-bottom: -8px;
`;
