import { UrlObject } from 'url';
import {
  ComponentType,
  FocusEvent,
  MouseEvent,
  forwardRef,
  useState,
  useEffect,
  useMemo
} from 'react';
import { Icon } from '../icons';
import { theme, ThemeColor } from '../../theme';
import { getColorWithAlpha } from '../../utils';
import { NextLinkComposed } from '../../next';
import { ButtonPrimitiveProps } from './button-primitive';
import { ButtonContainer, ButtonContent } from './button-layout';

type ButtonSize = 'large' | 'medium' | 'small';

export type IconButtonProps = ButtonPrimitiveProps & {
  icon: ComponentType;
  large?: boolean;
  small?: boolean;
  customColor?: string | ThemeColor;
  rounded?: boolean;
  active?: boolean;
  primary?: boolean;
  secondary?: boolean;
  href?: string | UrlObject;
  target?: string;
  padding?: number;
};

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  function _IconButton(
    {
      large,
      small,
      rounded,
      icon: IconComponent,
      customColor,
      active,
      children,
      onFocus,
      onBlur,
      onMouseOver,
      onMouseLeave,
      primary,
      secondary,
      href,
      padding = 6,
      ...forwardingProps
    },
    forwardingRef
  ) {
    const size: ButtonSize = small ? 'small' : large ? 'large' : 'medium';
    const iconSize = { large: 24, medium: 20, small: 16 }[size];
    const height = { large: 36, medium: 32, small: 28 }[size];

    const [color, setColor] = useState<ThemeColor>(
      primary ? 'black' : 'gray50'
    );
    const [focused, setFocused] = useState(false);
    const [hovered, setHovered] = useState(false);

    useEffect(() => {
      if (primary || focused || active) {
        setColor('black');
        return;
      }

      if (hovered) {
        setColor('gray70');
        return;
      }

      setColor('gray50');
    }, [active, focused, hovered, primary]);

    const colors = useMemo(() => {
      if (primary) {
        return {
          $backgroundColor: getColorWithAlpha(theme.colors.black, 0.05),
          $hoverOverlayColor: getColorWithAlpha(theme.colors.black, 0.05),
          $activeOverlayColor: getColorWithAlpha(theme.colors.black, 0.1)
        };
      }

      if (secondary) {
        return {
          $backgroundColor: theme.colors.background,
          $hoverOverlayColor: getColorWithAlpha(theme.colors.black, 0.05),
          $activeOverlayColor: getColorWithAlpha(theme.colors.black, 0.1)
        };
      }

      return {
        $backgroundColor: 'transparent',
        $hoverOverlayColor: getColorWithAlpha(theme.colors.black, 0.05),
        $activeOverlayColor: getColorWithAlpha(theme.colors.black, 0.1)
      };
    }, [primary, secondary]);

    const stateHandlers = {
      onFocus: (event: FocusEvent<HTMLButtonElement>) => {
        setFocused(true);
        if (onFocus) {
          onFocus(event);
        }
      },
      onBlur: (event: FocusEvent<HTMLButtonElement>) => {
        setFocused(false);
        if (onBlur) {
          onBlur(event);
        }
      },
      onMouseOver: (event: MouseEvent<HTMLButtonElement>) => {
        setHovered(true);
        if (onMouseOver) {
          onMouseOver(event);
        }
      },
      onMouseLeave: (event: MouseEvent<HTMLButtonElement>) => {
        setHovered(false);
        if (onMouseLeave) {
          onMouseLeave(event);
        }
      }
    };

    const Component = href ? NextLinkComposed : 'button';

    return (
      <ButtonContainer
        $padding={padding}
        $height={height}
        $borderRadius={rounded ? 100 : 4}
        $textColor={customColor || color}
        $active={active}
        href={href}
        ref={forwardingRef}
        as={Component}
        {...colors}
        {...stateHandlers}
        {...forwardingProps}
      >
        <ButtonContent>
          <Icon size={iconSize} component={IconComponent} />

          {children}
        </ButtonContent>
      </ButtonContainer>
    );
  }
);
