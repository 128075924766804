import pickBy from 'lodash/pickBy';
import { beaconMessages } from '@constants';
import {
  BeaconEvent,
  BeaconAnalyticEvent,
  BeaconIdentity,
  BeaconNavigateScreen
} from './types';

/*
  Public Facade for Beacon API
 */

type State = {
  interacting: boolean;
};

enum BeaconCustomField {
  ELFSIGHT_EMAIL = 16924,
  SUBSCRIPTION_PLAN = 35988
}

class Beacon {
  public state: State = {
    interacting: false
  };

  public setState = (state: Partial<State>) => {
    this.state = {
      ...this.state,
      ...state
    };
  };

  public display = (visible: boolean) => {
    if (this.state.interacting && !visible) {
      return;
    }

    window.Beacon('config', {
      display: {
        style: visible ? 'icon' : 'manual'
      },
      enableFabAnimation: false
    });
  };

  public openChat = () => this.open('/ask/chat/');

  public open = (navigate?: BeaconNavigateScreen) => {
    this.display(true);
    this.setState({ interacting: true });
    window.Beacon('open');

    if (navigate) {
      window.Beacon('navigate', navigate);
    }
  };

  public article = (articleID: string, type?: 'sidebar' | 'modal') => {
    this.open('/');
    setTimeout(() => {
      window.Beacon('article', articleID, { type });
    }, 100);
  };

  public showMessage = (
    messageKey: keyof typeof beaconMessages,
    force = false
  ) => {
    this.display(true);
    this.setState({ interacting: true });

    window.Beacon('show-message', beaconMessages[messageKey], {
      delay: 200,
      force
    });
  };

  public identify = (data: BeaconIdentity) => {
    window.Beacon(
      'identify',
      pickBy(data, (v) => v !== undefined) as BeaconIdentity
    );
  };

  public prefill = ({
    name,
    email,
    subscriptionPlan
  }: Pick<BeaconIdentity, 'name' | 'email'> & {
    subscriptionPlan: string;
  }) => {
    window.Beacon('prefill', {
      name,
      email,
      fields: [
        {
          id: BeaconCustomField.SUBSCRIPTION_PLAN,
          value: subscriptionPlan
        }
      ]
    });
  };

  public suggest = () => {
    window.Beacon('suggest');
  };

  public event = (type: BeaconAnalyticEvent, args: Record<string, unknown>) => {
    window.Beacon('event', {
      type,
      ...args
    });
  };

  // eslint-disable-next-line
  public on = (type: BeaconEvent, callback: (...args: any[]) => void) => {
    window.Beacon('on', type, callback);
  };

  // eslint-disable-next-line
  public once = (type: BeaconEvent, callback: (...args: any[]) => void) => {
    window.Beacon('once', type, callback);
  };

  // eslint-disable-next-line
  public off = (type: BeaconEvent, callback: (...args: any[]) => void) => {
    window.Beacon('off', type, callback);
  };

  public logout = () => {
    window.Beacon('logout');
  };
}

export const beacon = new Beacon();
