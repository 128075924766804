import { IncomingHttpHeaders } from 'http';
import 'core-js/features/global-this';
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer';
import 'reflect-metadata';
import type { AppContext, AppProps as NextAppProps } from 'next/app';
import NextApp from 'next/app';
import Head from 'next/head';
import { StyleSheetManager } from 'styled-components';
import { ThemeProvider } from '@elfsight-universe/ui-common';
import {
  AppProviders,
  AppToaster,
  NextPageWithConfig,
  AppPageProvider
} from '@modules/_app';
import {
  useCatchRefParam,
  useCatchGADebugViewParam,
  useCatchUTMParams,
  useCatchAffiseParams,
  useCatchLandingPage,
  useCatchReferer
} from '@modules/_app/utils';
import { Analytics } from '@modules/analytics';
import { ErrorBoundary } from '@modules/_error/error-boundary';

if (typeof window !== 'undefined' && !window.ResizeObserver) {
  window.ResizeObserver = ResizeObserverPolyfill;
}

type AppProps = NextAppProps & {
  Component: NextPageWithConfig;
  headers?: IncomingHttpHeaders;
};

export default function App({ Component, pageProps, headers }: AppProps) {
  const pageConfig = Component.config ?? {};

  useCatchRefParam();
  useCatchGADebugViewParam();
  useCatchUTMParams();
  useCatchAffiseParams();
  useCatchLandingPage();
  useCatchReferer(headers);

  return (
    // StyleSheetManager is required to set disableCSSOMInjection
    // as a workaround for Yandex.Metrika CSSOM support absence
    <StyleSheetManager disableCSSOMInjection>
      <ThemeProvider>
        <ErrorBoundary>
          <AppPageProvider value={pageConfig}>
            <AppProviders>
              <Head>
                <title>Elfsight</title>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, maximum-scale=1"
                />
                <meta property="og:title" content="Elfsight Dashboard" />
              </Head>

              <Analytics />
              <AppToaster />

              <Component {...pageProps} />
            </AppProviders>
          </AppPageProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </StyleSheetManager>
  );
}

App.getInitialProps = async (context: AppContext) => {
  const ctx = await NextApp.getInitialProps(context);
  return { ...ctx, headers: context.ctx.req?.headers };
};
