import { OfferType } from '@elfsight-universe/service-core-contracts/deals';
import { PROMO_PAGE_URL } from '@modules/pack-plans-promo/constants';
import { useAppCurrentAppAliasContext } from '@modules/_app';
import { createUpgradeURL } from '@modules/upgrade';
import { useOffer } from '../offer-provider';
import {
  OfferCouponSlideIn,
  OfferCouponSlideInType
} from './offer-coupon-slide-in';

export function OfferSlideInLayout({ type }: { type?: OfferType }) {
  const { offer } = useOffer();
  const { currentAppAlias } = useAppCurrentAppAliasContext();

  const welcomeOfferButtonLink = currentAppAlias
    ? createUpgradeURL({ app: currentAppAlias })
    : PROMO_PAGE_URL;

  if (!type || !offer) {
    return null;
  }

  return {
    [OfferType.BIRTHDAY_SALE]: (
      <OfferCouponSlideIn
        {...offer}
        expiredAt={offer.deal?.expiredAt}
        headerBackgroundColor="promo"
        headerColor="white"
        offerType={OfferCouponSlideInType.BUTTON}
        buttonText="Grab Deal"
        buttonLink="/birthday-sale"
        width={290}
      />
    ),
    [OfferType.XMAS_SALE]: (
      <OfferCouponSlideIn
        {...offer}
        expiredAt={offer.deal?.expiredAt}
        headerBackgroundColor="xmas"
        headerColor="white"
        headerIconColor="xmas2"
        offerType={OfferCouponSlideInType.BUTTON}
        buttonText="Grab Deal"
        buttonLink="/christmas-sale"
        buttonColor="success"
      />
    ),
    [OfferType.BLACK_FRIDAY_SALE]: (
      <OfferCouponSlideIn
        {...offer}
        expiredAt={offer.deal?.expiredAt}
        headerBackgroundColor="black"
        headerColor="white"
        headerIconColor="brand"
        offerType={OfferCouponSlideInType.BUTTON}
        buttonText="Grab Deal"
        buttonLink="/black-friday-sale"
      />
    ),
    [OfferType.WELCOME_COUPON]: (
      <OfferCouponSlideIn
        {...offer}
        expiredAt={offer.deal?.expiredAt}
        headerBackgroundColor="success"
        headerColor="white"
        headerIconColor="white"
        offerType={OfferCouponSlideInType.BUTTON}
        buttonText="Grab Deal"
        buttonColor="success"
        buttonLink={welcomeOfferButtonLink}
      />
    ),
    [OfferType.SECOND_APP_COUPON]: (
      <OfferCouponSlideIn
        {...offer}
        coupon={offer.deal?.couponCode}
        expiredAt={offer.deal?.expiredAt}
        headerBackgroundColor="alert"
        couponColor="brandTransparent"
        couponBorderColor="brand"
      />
    )
  }[type];
}
