import { Exclude, Expose, Type } from 'class-transformer';
import { IsNotEmpty } from 'class-validator';
import { BillingError } from '../errors';
import {
  BillingInterval,
  PlanGrade,
  PlanType,
  SubscriptionStatus
} from './enums';
import { PlanPolicy, StandardPaidPlanProperties } from './classes';
import { getTransformClassForPlanProps } from './utils';

export const GET_SWITCH_SUBSCRIPTION_PLAN_DETAILS_PATH =
  'billing/get-switch-subscription-plan-details';

export class GetSwitchSubscriptionPlanDetailsRequest {
  @IsNotEmpty()
  planPid: string;

  @IsNotEmpty()
  subscriptionPid: string;
}

@Exclude()
export class GetSwitchSubscriptionPlanDetailsResponse {
  @Expose()
  @Type(() => GetSwitchSubscriptionPlanDetailsResponseSubscription)
  subscription: {
    pid: string;
    status: SubscriptionStatus;
    effectivePlanPolicy: {
      viewsLimit: number | null;
      widgetsLimit: number;
      projectsLimit: number;
      collaboratorsLimit: number;
    };
    updatePaymentMethodURL?: string;
    hasInstantExtensions: boolean;
    plan: {
      name: string;
      type: PlanType;
      pid: string;
      properties: StandardPaidPlanProperties;
      policy: {
        viewsLimit: number | null;
        widgetsLimit: number;
        projectsLimit: number;
        collaboratorsLimit: number;
      };
      appsCount: number;
    };
  };

  @Expose()
  @Type(() => GetSwitchSubscriptionPlanDetailsResponsePlan)
  plans: GetSwitchSubscriptionPlanDetailsResponsePlan[];
}

@Exclude()
export class GetSwitchSubscriptionPlanDetailsResponseSubscription {
  @Expose()
  pid: string;

  @Expose()
  status: SubscriptionStatus;

  @Expose()
  @Type(() => PlanPolicy)
  effectivePlanPolicy: {
    viewsLimit: number | null;
    widgetsLimit: number;
    projectsLimit: number;
    collaboratorsLimit: number;
  };

  @Expose()
  hasInstantExtensions: boolean;

  @Expose()
  updatePaymentMethodURL?: string;

  @Expose()
  @Type(() => GetSwitchSubscriptionPlanDetailsResponsePlanForSubscription)
  plan: {
    name: string;
    type: PlanType;
    pid: string;
    properties: StandardPaidPlanProperties;
    policy: {
      viewsLimit: number | null;
      widgetsLimit: number;
      projectsLimit: number;
      collaboratorsLimit: number;
    };
    appsCount: number;
  };
}

@Exclude()
export class GetSwitchSubscriptionPlanDetailsResponsePlan {
  @Expose()
  name: string;

  @Expose()
  type: PlanType;

  @Expose()
  pid: string;

  @Expose()
  properties: {
    grade: PlanGrade;
    billingInterval: BillingInterval;
    price: number;
    icon?: string;
  };

  @Expose()
  @Type(() => PlanPolicy)
  policy: {
    viewsLimit: number | null;
    widgetsLimit: number;
    projectsLimit: number;
    collaboratorsLimit: number;
  };

  @Expose()
  appsCount: number;
}

@Exclude()
export class GetSwitchSubscriptionPlanDetailsResponsePlanForSubscription {
  @Expose()
  name: string;

  @Expose()
  type: PlanType;

  @Expose()
  pid: string;

  @Expose()
  @Type(getTransformClassForPlanProps)
  properties:
    | {
        grade: PlanGrade;
        billingInterval: BillingInterval;
        price: number;
        icon?: string;
      }
    | object;

  @Expose()
  @Type(() => PlanPolicy)
  policy: {
    viewsLimit: number | null;
    widgetsLimit: number;
    projectsLimit: number;
    collaboratorsLimit: number;
  };

  @Expose()
  appsCount: number;
}

export type GetSwitchSubscriptionPlanDetailsResponseError =
  | BillingError.SUBSCRIPTION_DOES_NOT_EXIST
  | BillingError.PLAN_DOES_NOT_EXIST
  | BillingError.SUBSCRIPTION_CANNOT_BE_UPDATED;
