import { useQuery } from '@tanstack/react-query';
import {
  GET_PACK_APPS_LIST_WITH_CATEGORIES_PATH,
  GetPackAppsListWithCategoriesResponse
} from '@elfsight-universe/service-core-contracts/widget';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function usePackAppsListWithCategoriesQuery() {
  const { isAuthenticated } = useUser();

  return useQuery<GetPackAppsListWithCategoriesResponse>(
    [GET_PACK_APPS_LIST_WITH_CATEGORIES_PATH],
    () =>
      client
        .get(GET_PACK_APPS_LIST_WITH_CATEGORIES_PATH)
        .json<GetPackAppsListWithCategoriesResponse>(),
    {
      retry: false,
      enabled: isAuthenticated
    }
  );
}
