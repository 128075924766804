import { Exclude, Expose, Type } from 'class-transformer';
import {
  IsEnum,
  IsNumber,
  IsOptional,
  IsString,
  IsUUID,
  Max,
  Min
} from 'class-validator';
import { Project } from '../project/classes';
import { PaginatedRequest } from '../paginated-request';
import { PaginationResponse } from '../pagination-response';
import { SortWidgetsVariant, WidgetStatus, WidgetVisibility } from './enums';

export const GET_WIDGETS_LIST_PATH = 'widget/get-widgets-list';

export class GetWidgetsListRequest extends PaginatedRequest {
  @IsString()
  @IsOptional()
  appAlias?: string;

  @IsUUID()
  @IsOptional()
  projectPid?: string;

  @IsNumber()
  @Type(() => Number)
  @IsOptional()
  @Min(1)
  page = 1;

  @IsNumber()
  @Type(() => Number)
  @IsOptional()
  @Min(1)
  @Max(50)
  itemsPerPage = 10;

  @IsString()
  @IsOptional()
  search?: string;

  @IsOptional()
  @IsEnum(SortWidgetsVariant)
  sortWidgetsVariant?: SortWidgetsVariant;
}

@Exclude()
export class GetWidgetsListResponse {
  @Expose()
  @Type(() => PaginationResponse)
  meta: PaginationResponse;

  @Expose()
  @Type(() => GetWidgetsListResponseWidget)
  payload: GetWidgetsListResponseWidget[];
}

@Exclude()
export class GetWidgetsListResponseWidgetThumbnail {
  @Expose()
  url: string;

  @Expose()
  width: number;

  @Expose()
  height: number;

  @Expose()
  staled: boolean;
}

@Exclude()
export class GetWidgetsListResponseApp {
  @Expose()
  name: string;

  @Expose()
  alias: string;

  @Expose()
  canExportData: boolean;

  @Expose()
  icon: string;
}

@Exclude()
export class GetWidgetsListResponseWidget {
  @Expose()
  pid: string;

  @Expose()
  createdAt: string;

  @Expose()
  updatedAt: string;

  @Expose()
  name: string;

  @Expose()
  status: WidgetStatus;

  @Expose()
  visibility: WidgetVisibility;

  @Expose()
  isDraft: boolean;

  @Expose()
  @Type(() => GetWidgetsListResponseApp)
  app: GetWidgetsListResponseApp;

  @Expose()
  @Type(() => Project)
  assignedProject?: Project;

  @Expose()
  @Type(() => GetWidgetsListResponseWidgetThumbnail)
  thumbnail?: GetWidgetsListResponseWidgetThumbnail;
}
