import { useQuery } from '@tanstack/react-query';
import {
  GET_PACK_PLAN_UPGRADE_ELIGIBILITY,
  GetPackPlanUpgradeEligibilityResponse
} from '@elfsight-universe/service-core-contracts/billing/get-pack-plan-upgrade-eligibility';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function usePackPlanUpgradeEligibilityQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetPackPlanUpgradeEligibilityResponse>(
    [GET_PACK_PLAN_UPGRADE_ELIGIBILITY],
    () => client.get(GET_PACK_PLAN_UPGRADE_ELIGIBILITY).json(),
    {
      enabled: isAuthenticated
    }
  );
}
