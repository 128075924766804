import { Exclude, Expose, Type } from 'class-transformer';
import {
  BillingInterval,
  PlanGrade,
  PlanType,
  SubscriptionStatus
} from './enums';
import { getTransformClassForPlanProps } from './utils';

export const GET_PAID_SUBSCRIPTIONS_PATH = 'billing/get-paid-subscriptions';

@Exclude()
export class GetPaidSubscriptionsResponse {
  @Expose()
  payload: GetPaidSubscriptionsResponseModel[];
}

@Exclude()
export class GetPaidSubscriptionsResponseModel {
  @Expose()
  pid: string;

  @Expose()
  status: SubscriptionStatus;

  @Expose()
  @Type(() => GetPaidSubscriptionsResponseApp)
  app?: {
    icon: string;
    alias: string;
    category: {
      color: string;
    };
  };

  @Expose()
  @Type(() => GetPaidSubscriptionsResponseApp)
  upgradeAssociatedApp?: {
    icon: string;
    alias: string;
    category: {
      color: string;
    };
  };

  @Expose()
  @Type(() => GetPaidSubscriptionsResponsePlan)
  plan: {
    name: string;
    type: PlanType;
    properties: any;
  };

  @Expose()
  @Type(() => GetPaidSubscriptionsResponseOtherData)
  data: {
    updateUrl: string;
    nextPaymentAmount: string;
    nextBillDate: Date | string;
  };

  @Expose()
  isSubscriptionCancelled: boolean;

  @Expose()
  isSubscriptionCancellationScheduled: boolean;

  @Expose()
  canRevokeSubscriptionCancellation: boolean;

  @Expose()
  canReactivateSubscription: boolean;

  @Expose()
  canScheduleSubscriptionCancellation: boolean;

  @Expose()
  isUnverifiedSubscription: boolean;

  @Expose()
  billingInterval: string;

  @Expose()
  upgradeToYearlyPlanPid: string | null;

  @Expose()
  isPausedSubscription: boolean;
}

@Exclude()
export class GetPaidSubscriptionsResponseApp {
  @Expose()
  icon: string;

  @Expose()
  alias: string;

  @Expose()
  @Type(() => GetPaidSubscriptionsResponseAppCategory)
  category: {
    color: string;
  };
}

@Exclude()
export class GetPaidSubscriptionsResponseAppCategory {
  @Expose()
  color: string;
}

@Exclude()
export class GetPaidSubscriptionsResponsePlan {
  @Expose()
  name: string;

  @Expose()
  type: PlanType;

  @Expose()
  @Type(getTransformClassForPlanProps)
  properties:
    | {
        grade: PlanGrade;
        billingInterval: BillingInterval;
        price: number;
        icon?: string;
      }
    | object;
}

@Exclude()
export class GetPaidSubscriptionsResponseOtherData {
  @Expose()
  updateUrl: string;

  @Expose()
  nextPaymentAmount: string;

  @Expose()
  nextBillDate: Date | string;
}
