import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  GET_APP_USAGE_STATISTICS_PATH,
  GetAppUsageStatisticsRequest,
  GetAppUsageStatisticsResponse,
  GetAppUsageStatisticsResponseError
} from '@elfsight-universe/service-core-contracts/billing/get-app-usage-statistics';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useAppUsageStatisticsQuery({
  appAlias,
  minusMonths
}: GetAppUsageStatisticsRequest) {
  const { isAuthenticated } = useUser();
  const queryClient = useQueryClient();

  const getQueryKey = (minusMonths: number) => [
    GET_APP_USAGE_STATISTICS_PATH,
    appAlias,
    minusMonths
  ];

  const queryFn = (minusMonths: number) =>
    client
      .get(GET_APP_USAGE_STATISTICS_PATH, {
        searchParams: { appAlias, minusMonths }
      })
      .json<GetAppUsageStatisticsResponse>();

  return useQuery<
    GetAppUsageStatisticsResponse,
    GetAppUsageStatisticsResponseError
  >(getQueryKey(minusMonths), () => queryFn(minusMonths), {
    enabled: Boolean(appAlias) && isAuthenticated,
    keepPreviousData: true,
    onSuccess: () => {
      queryClient.prefetchQuery(getQueryKey(minusMonths + 1), () =>
        queryFn(minusMonths + 1)
      );
    }
  });
}
