import styled from 'styled-components';
import {
  Envelope,
  QrCode,
  FacebookMessengerMulticolor,
  FacebookMulticolor,
  TwitterMulticolor,
  WhatsappMulticolor
} from '@elfsight/icons';
import { IconButton, Tooltip } from '@elfsight-universe/ui-common';
import { FACEBOOK_APP_ID } from '@constants';
import { sendAnalyticsEvent } from '@api';

const SHARE_TEXT = 'Look ma! A cool widget by Elfsight';

const SHARE_CONFIG = [
  {
    id: 'facebook',
    icon: FacebookMulticolor,
    onClick: (link: string) => {
      window.open(`https://www.facebook.com/sharer.php?u=${link}`);
    },
    tooltip: 'Share on Facebook'
  },
  {
    id: 'twitter',
    icon: TwitterMulticolor,
    onClick: (link: string) => {
      window.open(`https://twitter.com/share?url=${link}`);
    },
    tooltip: 'Share on Twitter'
  },
  {
    id: 'email',
    icon: Envelope,
    onClick: (link: string) => {
      window.open(`mailto:?body=${link}`);
    },
    tooltip: 'Share via Email'
  },
  {
    id: 'facebook-messenger',
    icon: FacebookMessengerMulticolor,
    onClick: (link: string) => {
      const params = new URLSearchParams({
        link,
        app_id: FACEBOOK_APP_ID,
        redirect_uri: document.location.href,
        message: SHARE_TEXT
      });
      window.open(`https://www.facebook.com/dialog/send?${params}`);
    },
    tooltip: 'Share via Facebook Messenger'
  },
  {
    id: 'whatsapp',
    icon: WhatsappMulticolor,
    onClick: (link: string) => {
      window.open(`https://api.whatsapp.com/send?text=${link}`);
    },
    tooltip: 'Share via WhatsApp'
  },
  {
    id: 'qr-code',
    icon: QrCode,
    onClick: (link: string) => {
      const params = new URLSearchParams({
        data: link,
        size: '400x400'
      });
      window.open(`https://api.qrserver.com/v1/create-qr-code/?${params}`);
    },
    tooltip: 'Generate QR'
  }
];

export type ShareLinkShareProps = { link: string; widgetPid: string };

export function ShareLinkShare({ link, widgetPid }: ShareLinkShareProps) {
  return (
    <Grid>
      {SHARE_CONFIG.map(({ id, icon, onClick, tooltip }) => {
        const handleClick = () => {
          sendAnalyticsEvent({
            name: 'widget_link_share',
            params: {
              method: id,
              widget_id: widgetPid
            }
          });
          onClick(link);
        };

        return (
          <Tooltip key={id} content={tooltip}>
            <IconButton
              large
              icon={icon}
              onClick={handleClick}
              customColor="black"
            />
          </Tooltip>
        );
      })}
    </Grid>
  );
}

const Grid = styled.div`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(36px, 1fr));
  grid-gap: 8px;
`;
