import { useQuery } from '@tanstack/react-query';
import {
  GET_SINGLE_APP_PLANS_PATH,
  GetSingleAppPlansRequest,
  GetSingleAppPlansResponse,
  GetSingleAppPlansResponseError
} from '@elfsight-universe/service-core-contracts/billing/get-single-app-plans';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useSingleAppPlansQuery(
  dto: Omit<GetSingleAppPlansRequest, 'appAlias'> & {
    appAlias: string | undefined;
  }
) {
  const { isAuthenticated } = useUser();

  const searchParams = new URLSearchParams();

  if (dto.appAlias) {
    searchParams.append('appAlias', dto.appAlias);
  }
  if (dto.withoutLitePlans !== undefined) {
    searchParams.append('withoutLitePlans', dto.withoutLitePlans.toString());
  }

  return useQuery<GetSingleAppPlansResponse, GetSingleAppPlansResponseError>(
    [GET_SINGLE_APP_PLANS_PATH, dto.appAlias, dto.withoutLitePlans],
    () =>
      client
        .get(GET_SINGLE_APP_PLANS_PATH, {
          searchParams
        })
        .json(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: Boolean(dto.appAlias) && isAuthenticated
    }
  );
}
