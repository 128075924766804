import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  GET_APP_USAGE_STATISTICS_BY_WIDGETS_PATH,
  GetAppUsageStatisticsByWidgetsRequest,
  GetAppUsageStatisticsByWidgetsResponse,
  GetAppUsageStatisticsByWidgetsResponseError
} from '@elfsight-universe/service-core-contracts/billing/get-app-usage-statistics-by-widgets';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useAppUsageStatisticsByWidgetsQuery(
  dto: GetAppUsageStatisticsByWidgetsRequest
) {
  const { isAuthenticated } = useUser();
  const queryClient = useQueryClient();

  const getQueryKey = (minusMonths: number) => [
    GET_APP_USAGE_STATISTICS_BY_WIDGETS_PATH,
    ...Object.values({ ...dto, minusMonths })
  ];

  const queryFn = (minusMonths: number) =>
    client
      .get(GET_APP_USAGE_STATISTICS_BY_WIDGETS_PATH, {
        searchParams: { ...dto, minusMonths }
      })
      .json<GetAppUsageStatisticsByWidgetsResponse>();

  return useQuery<
    GetAppUsageStatisticsByWidgetsResponse,
    GetAppUsageStatisticsByWidgetsResponseError
  >(getQueryKey(dto.minusMonths), () => queryFn(dto.minusMonths), {
    enabled: Boolean(dto.appAlias) && isAuthenticated,
    keepPreviousData: true,
    onSuccess: () => {
      queryClient.prefetchQuery(getQueryKey(dto.minusMonths + 1), () =>
        queryFn(dto.minusMonths + 1)
      );
    }
  });
}
