import { toast } from 'react-hot-toast';
import Router from 'next/router';
import { useResetBlackFridayRelatedQueries } from 'modules/instant-extension-campaign/utils';
import { SelectPlanAction } from '@elfsight-universe/service-core-contracts/billing/enums/select-plan-action.enum';
import { useCreateLiteSubscriptionMutation } from '@api';
import { HEADER_CLOSE_URL_PARAM } from '@modules/upgrade';
import { useHistory } from '@modules/_app';
import { PricingTablePlan } from '../pricing-table';
import { useUpgradeContext } from '../upgrade-context';
import { usePaddleSubscribe } from './use-paddle-subscribe';
import { useSubscribeRedirect } from './use-subscribe-redirect';
import { useInvalidateUpgradeRelatedQueries } from './use-invalidate-upgrade-related-queries';
import { useIsSecondAppDealPromising } from './use-is-second-app-deal-promising';
import { useActivateSubscription } from './use-activate-subscription';

export function useSubscribe(
  plan: PricingTablePlan
): [boolean, () => Promise<any>] {
  const { history } = useHistory();
  const { data } = useUpgradeContext();
  const handleRedirect = useSubscribeRedirect();
  const invalidateUpgradeRelatedQueries = useInvalidateUpgradeRelatedQueries();
  const resetBlackFridayRelatedQueries = useResetBlackFridayRelatedQueries();
  const secondAppDealPromising = useIsSecondAppDealPromising(plan);

  const { mutate: createLiteSubscription, isLoading: isCreateLiteLoading } =
    useCreateLiteSubscriptionMutation(() => {
      invalidateUpgradeRelatedQueries();
      handleRedirect();
    });

  const [isLoadingActivateSubscription, activateSubscription] =
    useActivateSubscription(() => {
      invalidateUpgradeRelatedQueries();
      resetBlackFridayRelatedQueries();

      handleRedirect({
        isPaidSubscription: true,
        secondAppDealPromising
      });
    });

  const [isLoadingPaddleSubscribe, paddleSubscribe] = usePaddleSubscribe(
    async ({ product, checkout }) => {
      toast.success(`Successfully subscribed to "${product.name}"`);

      const subscriptionPid = JSON.parse(checkout.passthrough).subscriptionPid;

      activateSubscription(subscriptionPid, checkout.id);
    }
  );

  const subscribe = async () => {
    if (plan.isLitePlan) {
      return createLiteSubscription({ planPid: plan.pid });
    }

    if (data?.selectPlanAction === SelectPlanAction.SUBSCRIBE_VIA_PADDLE) {
      return paddleSubscribe(plan.pid);
    }

    if (data?.selectPlanAction === SelectPlanAction.UPGRADE_VIA_PADDLE) {
      const headerCloseParam =
        (Router.query[HEADER_CLOSE_URL_PARAM] as string | undefined) ||
        history[history.length - 2] ||
        '/';

      const upgradeParams = new URLSearchParams({
        to: plan.pid,
        from: data?.effectiveSubscriptionPid,
        [HEADER_CLOSE_URL_PARAM]: headerCloseParam
      });

      return Router.push(`/upgrade-confirm?${upgradeParams}`);
    }
  };

  const isLoading =
    isCreateLiteLoading ||
    isLoadingPaddleSubscribe ||
    isLoadingActivateSubscription;

  return [isLoading, subscribe];
}
