import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_PROJECT_COLLABORATORS_PATH,
  GetProjectCollaboratorsError,
  GetProjectCollaboratorsResponse
} from '@elfsight-universe/service-core-contracts/project';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function useProjectCollaboratorsQuery(
  projectPid: string,
  {
    enabled,
    ...options
  }: UseQueryOptions<
    GetProjectCollaboratorsResponse,
    GetProjectCollaboratorsError
  > = {}
) {
  const { isAuthenticated } = useUser();
  return useQuery<
    GetProjectCollaboratorsResponse,
    GetProjectCollaboratorsError
  >(
    [GET_PROJECT_COLLABORATORS_PATH, projectPid],
    () =>
      client
        .get(GET_PROJECT_COLLABORATORS_PATH, {
          searchParams: {
            projectPid
          }
        })
        .json<GetProjectCollaboratorsResponse>(),
    {
      enabled: enabled && isAuthenticated,
      ...options
    }
  );
}
