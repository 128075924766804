import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_PRICING_INCLUDED_APPS_PATH,
  GetPricingIncludedAppsResponse
} from '@elfsight-universe/service-core-contracts/widget';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function usePricingIncludedAppsQuery(
  options: UseQueryOptions<GetPricingIncludedAppsResponse> = {}
) {
  const { isAuthenticated } = useUser();
  return useQuery<GetPricingIncludedAppsResponse>(
    [GET_PRICING_INCLUDED_APPS_PATH],
    () =>
      client
        .get(GET_PRICING_INCLUDED_APPS_PATH)
        .json<GetPricingIncludedAppsResponse>(),
    {
      enabled: isAuthenticated,
      ...options
    }
  );
}
