import { MouseEvent } from 'react';
import styled, { css } from 'styled-components';
import { Close20 } from '@elfsight/icons';
import { H2, IconButton } from '@elfsight-universe/ui-common';
import { customScrollbarMixin } from '@elfsight-universe/ui-common/src/components/custom-scrollbar-mixin';
import { AnnouncementsObserver } from '@modules/announcements/components/announcements-observer';
import { AnnouncementsList } from '@modules/announcements/announcements-list';

const HEADER_HEIGHT = 64;

export type AnnouncementsContentProps = {
  onCloseButtonClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  withClose?: boolean;
  maxHeight: number;
};

export function AnnouncementsContent({
  onCloseButtonClick,
  withClose,
  maxHeight
}: AnnouncementsContentProps) {
  const contentMaxHeight = maxHeight - HEADER_HEIGHT;

  return (
    <Container>
      <Header>
        <Title>What&apos;s New</Title>
        {withClose && (
          <PopoverCloseButton
            large
            rounded
            icon={Close20}
            onClick={onCloseButtonClick}
          />
        )}
      </Header>

      <Content _contentMaxHeight={contentMaxHeight}>
        <AnnouncementsList />
        <AnnouncementsObserver />
      </Content>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const Header = styled.div`
  box-sizing: border-box;
  padding: 20px 64px 20px 24px;
  height: ${HEADER_HEIGHT}px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray10};;
`;

const Title = styled(H2)`
  padding-right: 40px;
`;

const Content = styled.div<{ _contentMaxHeight?: number }>`
  position: relative;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 8px;

  ${({ _contentMaxHeight }) =>
    _contentMaxHeight &&
    css`
      max-height: ${_contentMaxHeight}px;
    `};

  ${customScrollbarMixin}
`;

const PopoverCloseButton = styled(IconButton)`
  position: absolute;
  right: 14px;
  top: 14px;
`;
