import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  GET_PACK_PLANS_PATH,
  GetPackPlansResponse
} from '@elfsight-universe/service-core-contracts/billing/get-pack-plans';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function usePackPlansQuery(
  appAlias?: string,
  options?: UseQueryOptions<GetPackPlansResponse>
) {
  const { isAuthenticated } = useUser();

  const searchParams = new URLSearchParams();
  if (appAlias) {
    searchParams.append('appAlias', appAlias);
  }

  return useQuery<GetPackPlansResponse>(
    [GET_PACK_PLANS_PATH, appAlias],
    () => client.get(GET_PACK_PLANS_PATH, { searchParams }).json(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: isAuthenticated,
      ...options
    }
  );
}
