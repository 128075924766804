import { useQuery } from '@tanstack/react-query';
import {
  GET_PAID_SUBSCRIPTIONS_PATH,
  GetPaidSubscriptionsResponse
} from '@elfsight-universe/service-core-contracts/billing/get-paid-subscriptions';
import { useUser } from '@modules/_app';
import { client } from '../client';

export function usePaidSubscriptionsQuery() {
  const { isAuthenticated } = useUser();
  return useQuery<GetPaidSubscriptionsResponse>(
    [GET_PAID_SUBSCRIPTIONS_PATH],
    () => client.get(GET_PAID_SUBSCRIPTIONS_PATH).json(),
    {
      enabled: isAuthenticated
    }
  );
}
